import { cloneDeep } from "lodash";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../utilities/constants";

export const SET_MONTHLY_PLAN_DATA_TABLE_DEFAULT_FILTERS = 'SET_MONTHLY_PLAN_DATA_TABLE_DEFAULT_FILTERS';
export const SET_MONTHLY_PLAN_DATA_TABLE_SELECTED_FILTERS = 'SET_MONTHLY_PLAN_DATA_TABLE_SELECTED_FILTERS';
export const SET_MONTHLY_PLAN_DATA = 'SET_MONTHLY_PLAN_TABLE_DATA';
export const SET_MONTHLY_PLAN_DATA_COPY = 'SET_MONTHLY_PLAN_DATA_COPY';
export const SET_MONTHLY_PLAN_DATA_TOTAL_RECORD_COUNT = 'SET_MONTHLY_PLAN_DATA_TOTAL_RECORD_COUNT';
export const SET_MONTHLY_PLAN_DATA_TABLE_PAGE_SIZE = 'SET_MONTHLY_PLAN_DATA_TABLE_PAGE_SIZE';
export const SET_MONTHLY_PLAN_DATA_TABLE_CURRENT_PAGE = 'SET_MONTHLY_PLAN_DATA_TABLE_CURRENT_PAGE';
export const SET_MONTHLY_PLAN_DATA_TABLE_TOGGLE_COLUMNS = 'SET_MONTHLY_PLAN_DATA_TABLE_TOGGLE_COLUMNS';
export const SET_MONTHLY_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST = 'SET_MONTHLY_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST';
export const SET_MONTHLY_PLAN_CATEGORY_LIST = 'SET_MONTHLY_PLAN_CATEGORY_LIST';
export const SET_MONTHLY_PLAN_DATA_TABLE_METRIC_FILTER_LIST = 'SET_MONTHLY_PLAN_DATA_TABLE_METRIC_FILTER_LIST';
export const SET_MONTHLY_PLAN_DATA_TABLE_EDIT= 'SET_MONTHLY_PLAN_DATA_TABLE_EDIT';
export const SET_MONTHLY_PLAN_DATA_TABLE_RESET= 'SET_MONTHLY_PLAN_DATA_TABLE_RESET';
export const SET_MONTHLY_PLAN_DATA_TABLE_CANCEL_UPDATES= 'SET_MONTHLY_PLAN_DATA_TABLE_CANCEL_UPDATES';
export const SET_MONTHLY_PLAN_DATA_ADD_SKU_UPLOADS= 'SET_MONTHLY_PLAN_DATA_ADD_SKU_UPLOADS';
export const SET_MONTHLY_PLAN_TABLE_DATA_LOADING = 'SET_MONTHLY_PLAN_TABLE_DATA_LOADING';
export const SET_MONTHLY_PLAN_DATA_REMOVE_SKU_UPLOADS= 'SET_MONTHLY_PLAN_DATA_REMOVE_SKU_UPLOADS';

export const setMonthlyPlanData = (data, isLoadingBoolValue = false) => {
    return {type:SET_MONTHLY_PLAN_DATA, payload:{data:cloneDeep(data), isLoading:isLoadingBoolValue}}
}

export const setMonthlyPlanDataClone = (data, isLoadingBoolValue = false) => {
    return {type:SET_MONTHLY_PLAN_DATA_COPY, payload:{displayData:data, isLoading:isLoadingBoolValue}}
}

export const setMonthlyPlanDefaultFilters = (filters) => ({
    type:SET_MONTHLY_PLAN_DATA_TABLE_DEFAULT_FILTERS,
    payload:cloneDeep(filters)
})
export const setMonthlyPlanDataTableAppliedFilters = (filters) => ({
    type:SET_MONTHLY_PLAN_DATA_TABLE_SELECTED_FILTERS,
    payload:cloneDeep(filters)
})

export const setMonthlyPlanCategoryList = (catList) => ({
    type:SET_MONTHLY_PLAN_CATEGORY_LIST,
    payload:cloneDeep(catList)
})

export const setMonthlyDataTableCategoryFilterList = (catList) => ({
    type:SET_MONTHLY_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST,
    payload:cloneDeep(catList)
})

export const setMonthlyDataTableMetricFilterList = (metricList) => ({
    type:SET_MONTHLY_PLAN_DATA_TABLE_METRIC_FILTER_LIST,
    payload:cloneDeep(metricList)
})
export const setMonthlyDataTableEditable = (editEnabled = false) => ({
    type:SET_MONTHLY_PLAN_DATA_TABLE_EDIT,
    payload:cloneDeep(editEnabled)
})
export const setMonthlyDataTableResetData = (editEnabled) => ({
    type:SET_MONTHLY_PLAN_DATA_TABLE_RESET,
    payload:cloneDeep(editEnabled)
})
export const setMonthlyDataCancelUpdates = (editEnabled) => ({
    type:SET_MONTHLY_PLAN_DATA_TABLE_CANCEL_UPDATES,
    payload:cloneDeep(editEnabled)
})
export const setMonthlyDataTotalRecordCount = (recordCount) => ({
    type:SET_MONTHLY_PLAN_DATA_TOTAL_RECORD_COUNT,
    payload:cloneDeep(recordCount)
})
export const setMonthlyPlanDataTablePageSize = (displayRecordCount = DEFAULT_TABLE_PAGE_SIZE) => ({
    type:SET_MONTHLY_PLAN_DATA_TABLE_PAGE_SIZE,
    payload:cloneDeep(displayRecordCount)
})
export const setMonthlyPlanDataTableCurrentPage = (displayCurrentPage = 1) => ({
    type:SET_MONTHLY_PLAN_DATA_TABLE_CURRENT_PAGE,
    payload:cloneDeep(displayCurrentPage)
})
export const setMonthlyPlanDataTableVisibleColumnsList = (columnsList) => ({
    type:SET_MONTHLY_PLAN_DATA_TABLE_TOGGLE_COLUMNS,
    payload:cloneDeep(columnsList)
})

export const setMonthlyPlanAddSkuUploads = (bool) => ({
    type:SET_MONTHLY_PLAN_DATA_ADD_SKU_UPLOADS,
    payload:bool
})

export const setMonthlyPlanTableDataLoading = (bool) => ({
    type:SET_MONTHLY_PLAN_TABLE_DATA_LOADING,
    payload:bool
})

export const setMonthlyPlanRemoveSkuUploads = (bool) => ({
    type:SET_MONTHLY_PLAN_DATA_REMOVE_SKU_UPLOADS,
    payload:bool
})
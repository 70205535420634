import { cloneDeep } from "lodash";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../utilities/constants";

export const SET_ADMIN_PANEL_ACTIVE_TAB_KEY = 'SET_ADMIN_PANEL_ACTIVE_TAB_KEY';

export const SET_APP_SCREENS_LIST_MAPPING = 'SET_APP_SCREENS_LIST_MAPPING';
export const SET_APP_USER_SCOPE_LIST_MAPPING = 'SET_APP_USER_SCOPE_LIST_MAPPING';
export const SET_APP_USER_EMAILS_LIST_MAPPING = 'SET_APP_USER_EMAILS_LIST_MAPPING';
export const SET_APP_COUNTRY_METRIC_KPI_LIST_MAPPING = 'SET_APP_COUNTRY_METRIC_KPI_LIST_MAPPING';

export const SET_APP_USERS_TABLE_LIST_MAPPING = 'SET_APP_USERS_TABLE_LIST_MAPPING';
export const SET_APP_USERS_TABLE_CURRENT_PAGE = 'SET_APP_USERS_TABLE_CURRENT_PAGE';
export const SET_APP_USERS_TABLE_PAGE_SIZE = 'SET_APP_USERS_TABLE_PAGE_SIZE';
export const SET_APP_USERS_TABLE_TOTAL_PAGE_COUNT = 'SET_APP_USERS_TABLE_TOTAL_PAGE_COUNT';
export const SET_APP_USERS_TABLE_FILTERS = 'SET_APP_USERS_TABLE_FILTERS';


export const SET_APP_TARGETS_TABLE_LIST_MAPPING = 'SET_APP_TARGETS_TABLE_LIST_MAPPING';
export const SET_APP_TARGETS_TABLE_CURRENT_PAGE = 'SET_APP_TARGETS_TABLE_CURRENT_PAGE';
export const SET_APP_TARGETS_TABLE_PAGE_SIZE = 'SET_APP_TARGETS_TABLE_PAGE_SIZE';
export const SET_APP_TARGETS_TABLE_TOTAL_PAGE_COUNT = 'SET_APP_TARGETS_TABLE_TOTAL_PAGE_COUNT';
export const SET_APP_TARGETS_TABLE_FILTERS = 'SET_APP_TARGETS_TABLE_FILTERS';

export const SET_APP_NPI_TABLE_LIST_MAPPING = 'SET_APP_NPI_TABLE_LIST_MAPPING';
export const SET_APP_NPT_TABLE_CURRENT_PAGE = 'SET_APP_NPT_TABLE_CURRENT_PAGE';
export const SET_APP_NPI_TABLE_PAGE_SIZE = 'SET_APP_NPI_TABLE_PAGE_SIZE';
export const SET_APP_NPI_TABLE_TOTAL_PAGE_COUNT = 'SET_APP_NPI_TABLE_TOTAL_PAGE_COUNT';

export const SET_APP_METRIC_KPI_TABLE_LIST_MAPPING = 'SET_APP_METRIC_KPI_TABLE_LIST_MAPPING';
export const SET_APP_METRIC_KPI_TABLE_CURRENT_PAGE = 'SET_APP_METRIC_KPI_TABLE_CURRENT_PAGE';
export const SET_APP_METRIC_KPI_TABLE_PAGE_SIZE = 'SET_APP_METRIC_KPI_TABLE_PAGE_SIZE';
export const SET_APP_METRIC_KPI_TABLE_TOTAL_PAGE_COUNT = 'SET_APP_METRIC_KPI_TABLE_TOTAL_PAGE_COUNT';
export const SET_APP_METRIC_KPI_TABLE_FILTERS = 'SET_APP_METRIC_KPI_TABLE_FILTERS';


export const setAdminPanelActiveTabKey = (activeKey = 1) => ({
    type: SET_ADMIN_PANEL_ACTIVE_TAB_KEY,
    payload: cloneDeep(Number(activeKey))
});


export const setAppScreensList = (screensList = []) => ({
    type: SET_APP_SCREENS_LIST_MAPPING,
    payload: cloneDeep(screensList)
});


export const setAppUserScopeList = (scopeList = []) => ({
    type: SET_APP_USER_SCOPE_LIST_MAPPING,
    payload: cloneDeep(scopeList)
});


export const setAppCountryMetricKpisList = (kpiList = [], hasNext = false) => ({
    type: SET_APP_COUNTRY_METRIC_KPI_LIST_MAPPING,
    payload: { data: cloneDeep(kpiList), hasNext: hasNext }
});


export const setAppUserEmailsList = (
    usersList = [],
    hasNext = false,
    isLoading = false
) => {
    return {
        type: SET_APP_USER_EMAILS_LIST_MAPPING,
        payload: {
            data: cloneDeep(usersList),
            hasNext: hasNext,
            isLoading: isLoading,
        }
    }
};


export const setAppUsersTableList = (
    usersList = [],
    hasNext = false,
    isLoading = false,
    totalRecordCount = 0,
    originalState = {}
) => {
    return {
        type: SET_APP_USERS_TABLE_LIST_MAPPING,
        payload: {
            data: cloneDeep(usersList),
            hasNext: hasNext,
            isLoading: isLoading,
            currentPage: cloneDeep(originalState.currentPage),
            totalRecordCount: totalRecordCount,
            pageSize: cloneDeep(originalState.pageSize),
            tableFilters : cloneDeep(originalState.tableFilters)
        }
    }
};


export const setAppUsersTableCurrentPage = (
    currentPage = 1,
    originalState = {}
) => {
    return {
        type: SET_APP_USERS_TABLE_CURRENT_PAGE,
        payload: {
            data: cloneDeep(originalState.data),
            hasNext: originalState.hasNext,
            isLoading: originalState.isLoading,
            currentPage: currentPage,
            totalRecordCount: originalState.totalRecordCount,
            pageSize: originalState.pageSize,
            tableFilters : cloneDeep(originalState.tableFilters)
        }
    }
};


export const setAppUsersTablePageSize = (
    pageSize = DEFAULT_TABLE_PAGE_SIZE,
    currentPage = 1,
    originalState
) => {
    return {
        type: SET_APP_USERS_TABLE_PAGE_SIZE,
        payload: {
            currentPage: currentPage,
            pageSize: pageSize,
            // ...cloneDeep(originalState)
            data: cloneDeep(originalState.data),
            hasNext: originalState.hasNext,
            isLoading: originalState.isLoading,
            totalRecordCount: originalState.totalRecordCount,
            tableFilters : cloneDeep(originalState.tableFilters)
        }
    }
};


export const setAppTargetsList = (
    targetsList = [],
    hasNext = false,
    isLoading = false,
    totalRecordCount = 0,
    originalState = {}
) => {
    return {
        type: SET_APP_TARGETS_TABLE_LIST_MAPPING,
        payload: {
            data: cloneDeep(targetsList),
            hasNext: hasNext,
            isLoading: isLoading,
            currentPage: cloneDeep(originalState.currentPage),
            totalRecordCount: totalRecordCount,
            pageSize: cloneDeep(originalState.pageSize),
            tableFilters : cloneDeep(originalState.tableFilters)
        }
    }
};


export const setAppTargetsTableCurrentPage = (
    currentPage = 1,
    originalState = {}
) => {
    return {
        type: SET_APP_TARGETS_TABLE_CURRENT_PAGE,
        payload: {
            data: cloneDeep(originalState.data),
            hasNext: originalState.hasNext,
            isLoading: originalState.isLoading,
            currentPage: currentPage,
            totalRecordCount: originalState.totalRecordCount,
            pageSize: originalState.pageSize,
            tableFilters : cloneDeep(originalState.tableFilters)
        }
    }
};


export const setAppTargetsTablePageSize = (
    pageSize = DEFAULT_TABLE_PAGE_SIZE,
    currentPage = 1,
    originalState
) => {
    return {
        type: SET_APP_TARGETS_TABLE_PAGE_SIZE,
        payload: {
            data: cloneDeep(originalState.data),
            hasNext: originalState.hasNext,
            isLoading: originalState.isLoading,
            currentPage: currentPage,
            totalRecordCount: originalState.totalRecordCount,
            pageSize: pageSize,
            tableFilters : cloneDeep(originalState.tableFilters)
        }
    }
};


export const setAppNewProductsInformationList = (
    npiList = [],
    hasNext = false,
    isLoading = false,
    totalRecordCount = 0,
    originalState = {}
) => {
    return {
        type: SET_APP_NPI_TABLE_LIST_MAPPING,
        payload: {
            data: cloneDeep(npiList),
            hasNext: hasNext,
            isLoading: isLoading,
            currentPage: cloneDeep(originalState.currentPage),
            totalRecordCount: totalRecordCount,
            pageSize: cloneDeep(originalState.pageSize)
        }
    }
};


export const setAppNewProductsInformationTableCurrentPage = (
    currentPage = 1,
    originalState = {}
) => {
    return {
        type: SET_APP_NPT_TABLE_CURRENT_PAGE,
        payload: {
            data: cloneDeep(originalState.data),
            hasNext: originalState.hasNext,
            isLoading: originalState.isLoading,
            currentPage: currentPage,
            totalRecordCount: originalState.totalRecordCount,
            pageSize: originalState.pageSize
        }
    }
};


export const setAppNewProductsInformationTablePageSize = (
    pageSize = DEFAULT_TABLE_PAGE_SIZE,
    currentPage = 1,
    originalState
) => {
    return {
        type: SET_APP_NPI_TABLE_PAGE_SIZE,
        payload: {
            currentPage: currentPage,
            pageSize: pageSize,
            ...originalState
            // data: cloneDeep(originalState.data),
            // hasNext: originalState.hasNext,
            // isLoading: originalState.isLoading,
            // totalRecordCount: originalState.totalRecordCount,
            
        }
    }
};


export const setAppMetricKpisList = (
    metricKpisList = [],
    hasNext = false,
    isLoading = false,
    totalRecordCount = 0,
    originalState = {}
) => {
    return {
        type: SET_APP_METRIC_KPI_TABLE_LIST_MAPPING,
        payload: {
            data: cloneDeep(metricKpisList),
            hasNext: hasNext,
            isLoading: isLoading,
            currentPage: cloneDeep(originalState.currentPage),
            totalRecordCount: totalRecordCount,
            pageSize: cloneDeep(originalState.pageSize),
            tableFilters : cloneDeep(originalState.tableFilters)
        }
    }
};


export const setAppMetricKpisTableCurrentPage = (
    currentPage = 1,
    originalState = {}
) => {
    return {
        type: SET_APP_METRIC_KPI_TABLE_CURRENT_PAGE,
        payload: {
            data: cloneDeep(originalState.data),
            hasNext: originalState.hasNext,
            isLoading: originalState.isLoading,
            currentPage: currentPage,
            totalRecordCount: originalState.totalRecordCount,
            pageSize: originalState.pageSize,
            tableFilters : cloneDeep(originalState.tableFilters)
        }
    }
};


export const setAppMetricKpisTablePageSize = (
    pageSize = DEFAULT_TABLE_PAGE_SIZE,
    currentPage = 1,
    originalState
) => {
    return {
        type: SET_APP_METRIC_KPI_TABLE_PAGE_SIZE,
        payload: {
            data: cloneDeep(originalState.data),
            hasNext: originalState.hasNext,
            isLoading: originalState.isLoading,
            currentPage: currentPage,
            totalRecordCount: originalState.totalRecordCount,
            pageSize: pageSize,
            tableFilters : cloneDeep(originalState.tableFilters)
        }
    }
};



export const setAppUsersTableFilters = (
    tableFilters = {}, originalState = {}
) => {
    return {
        type: SET_APP_USERS_TABLE_FILTERS,
        payload: {
            data: cloneDeep(originalState.data),
            hasNext: originalState.hasNext,
            isLoading: originalState.isLoading,
            currentPage: originalState.currentPage,
            totalRecordCount: originalState.totalRecordCount,
            pageSize: originalState.pageSize,
            tableFilters : tableFilters
        }
    }
};


export const setAppMetricKpisTableFilters = (
    tableFilters = {}, originalState = {}
) => {
    return {
        type: SET_APP_METRIC_KPI_TABLE_FILTERS,
        payload: {
            data: cloneDeep(originalState.data),
            hasNext: originalState.hasNext,
            isLoading: originalState.isLoading,
            currentPage: originalState.currentPage,
            totalRecordCount: originalState.totalRecordCount,
            pageSize: originalState.pageSize,
            tableFilters : tableFilters
        }
    }
};

export const setAppTargetsTableFilters = (
    tableFilters = {}, originalState = {}
) => {
    return {
        type: SET_APP_TARGETS_TABLE_FILTERS,
        payload: {
            data: cloneDeep(originalState.data),
            hasNext: originalState.hasNext,
            isLoading: originalState.isLoading,
            currentPage: originalState.currentPage,
            totalRecordCount: originalState.totalRecordCount,
            pageSize: originalState.pageSize,
            tableFilters : tableFilters
        }
    }
};


import {
    SET_WEEKLY_PLAN_DATA_TABLE_DEFAULT_FILTERS,
    SET_WEEKLY_PLAN_DATA_TABLE_SELECTED_FILTERS,
    SET_WEEKLY_PLAN_DATA,
    SET_WEEKLY_PLAN_DATA_COPY,
    SET_WEEKLY_PLAN_DATA_TABLE_EDIT,
    SET_WEEKLY_PLAN_DATA_TABLE_CURRENT_PAGE,
    SET_WEEKLY_PLAN_DATA_TABLE_PAGE_SIZE,
    SET_WEEKLY_PLAN_DATA_TABLE_TOGGLE_COLUMNS,
    SET_WEEKLY_PLAN_DATA_TOTAL_RECORD_COUNT,
    SET_WEEKLY_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST,
    SET_WEEKLY_PLAN_DATA_TABLE_METRIC_FILTER_LIST,
    SET_WEEKLY_PLAN_DATA_ADD_SKU_UPLOADS,
    SET_WEEKLY_PLAN_TABLE_DATA_LOADING,
    SET_WEEKLY_PLAN_DATA_REMOVE_SKU_UPLOADS
} from '../actions/weekly-plan-actions';
import {
    SET_WEEKLY_PLAN_SUMMARY_API_REFRESH,
    SET_WEEKLY_PLAN_CURRENT_YEAR_SUMMARY,
    SET_WEEKLY_PLAN_LAST_YEAR_SUMMARY,
    SET_WEEKLY_PLAN_DIFF_CURRENT_LAST_SUMMARY,
} from "../actions/summary-weekly-plan-action";
import { DEFAULT_TABLE_PAGE_SIZE } from '../../utilities/constants';


const initialState = {
    defaultFilters: { MetricId: [], Year: '', CategoryId: [], Sku: '' },
    plannedData: { data: [], isLoading: false },
    plannedDataClone: { displayData: [], isLoading: false },
    totalRecordCount: 1,
    dataTableCategoryFilterList: [],
    dataTableMetricFilterList: [],
    dataTableAppliedFilters: { MetricId: null, Sku: null },
    currentPage: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    isEditable: false,
    addSkuUploads: { isSkuToBeUploaded: false, isTemplateDownloaded: false, details: {} },
    removeSkuUploads: { isSkuToBeUploaded: false, isTemplateDownloaded: false, details: {} },
    visibleColumnsList: [],
    isTableDataLoading: false,
    error: null,
    hasFinancialSummaryData: false,
    financialSummary: {
        currentYear: { data: [], summary:[], isLoading: false, expandedData:[] },
        lastYear: { data: [], summary:[], isLoading: false, expandedData:[] },
        diffCurrentVsLast: { data: [], summary:[], isLoading: false, expandedData:[] },
    },
};

const weeklyPlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WEEKLY_PLAN_DATA_TABLE_DEFAULT_FILTERS:
            return { ...state, defaultFilters: action.payload };

        case SET_WEEKLY_PLAN_DATA:
            return { ...state, plannedData: action.payload };

        case SET_WEEKLY_PLAN_DATA_COPY:
            return { ...state, plannedDataClone: action.payload };

        case SET_WEEKLY_PLAN_DATA_TABLE_EDIT:
            return { ...state, isEditable: action.payload };

        case SET_WEEKLY_PLAN_DATA_TABLE_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };

        case SET_WEEKLY_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST:
            return { ...state, dataTableCategoryFilterList: action.payload };

        case SET_WEEKLY_PLAN_DATA_TABLE_METRIC_FILTER_LIST:
            return { ...state, dataTableMetricFilterList: action.payload };

        case SET_WEEKLY_PLAN_DATA_TABLE_SELECTED_FILTERS:
            return { ...state, dataTableAppliedFilters: action.payload };

        case SET_WEEKLY_PLAN_DATA_TABLE_PAGE_SIZE:
            return { ...state, pageSize: action.payload };

        case SET_WEEKLY_PLAN_DATA_TABLE_TOGGLE_COLUMNS:
            return { ...state, visibleColumnsList: action.payload };

        case SET_WEEKLY_PLAN_DATA_TOTAL_RECORD_COUNT:
            return { ...state, totalRecordCount: action.payload };

        case SET_WEEKLY_PLAN_DATA_ADD_SKU_UPLOADS:
            return { ...state, addSkuUploads: action.payload };

        case SET_WEEKLY_PLAN_SUMMARY_API_REFRESH:
            return { ...state, hasFinancialSummaryData: action.payload }

        case SET_WEEKLY_PLAN_CURRENT_YEAR_SUMMARY:
            return { ...state, financialSummary: action.payload };

        case SET_WEEKLY_PLAN_LAST_YEAR_SUMMARY:
            return { ...state, financialSummary: action.payload };

        case SET_WEEKLY_PLAN_DIFF_CURRENT_LAST_SUMMARY:
            return { ...state, financialSummary: action.payload };

        case SET_WEEKLY_PLAN_TABLE_DATA_LOADING:
            return { ...state, isTableDataLoading: action.payload };

        case SET_WEEKLY_PLAN_DATA_REMOVE_SKU_UPLOADS:
            return { ...state, removeSkuUploads: action.payload };

        default:
            return state;
    }

}

export default weeklyPlanReducer;

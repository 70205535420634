

import { DEFAULT_TABLE_PAGE_SIZE } from "../../utilities/constants";
import {
    SET_PRICES_DATA_PRICES_UPLOADS,
    SET_PRICES_DATA_REMOVE_UPLOADS,
    SET_PRICES_DATA_TABLE_COUNTRY_FILTER_LIST,
    SET_PRICES_DATA_TABLE_CURRENT_PAGE,
    SET_PRICES_DATA_TABLE_DEFAULT_FILTERS,
    SET_PRICES_DATA_TABLE_EDITABLE,
    SET_PRICES_DATA_TABLE_PAGE_SIZE,
    SET_PRICES_DATA_TABLE_SELECTED_FILTERS,
    SET_PRICES_DATA_TABLE_TOGGLE_COLUMNS,
    SET_PRICES_DATA_TOTAL_RECORD_COUNT,
    SET_PRICES_TABLE_DATA,
    SET_PRICES_TABLE_DATA_COPY,
    SET_PRICES_TABLE_DATA_LOADING
} from "../actions/prices-action";


const initialState = {
    defaultFilters: { CountryId: [], Year: '', Month: [], Sku: ''},
    plannedData: { data: [], isLoading: false },
    plannedDataClone: { displayData: [], isLoading: false },
    totalRecordCount: 1,
    dataTableCountryFilterList: [],
    dataTableAppliedFilters: { CountryId: null, Sku: null },
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    currentPage: 1,
    isEditable: false,
    visibleColumnsList: [],
    priceUploads: { isPriceToBeUploaded: false, isTemplateDownloaded: false, Year: {} },
    removePricesUpload: { isRemovePriceFileUploaded: false, isTemplateDownloaded: false, Year: {} },
    error: null,
    isTableDataLoading: false
};

const priceStateReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_PRICES_DATA_TABLE_DEFAULT_FILTERS:
            return { ...state, defaultFilters: action.payload };

        case SET_PRICES_TABLE_DATA:
            return { ...state, plannedData: action.payload };

        case SET_PRICES_TABLE_DATA_COPY:
            return { ...state, plannedDataClone: action.payload };

        case SET_PRICES_DATA_TOTAL_RECORD_COUNT:
            return { ...state, totalRecordCount: action.payload };

        case SET_PRICES_DATA_TABLE_COUNTRY_FILTER_LIST:
            return { ...state, dataTableCountryFilterList: action.payload };

        case SET_PRICES_DATA_TABLE_SELECTED_FILTERS:
            return { ...state, dataTableAppliedFilters: action.payload };

        case SET_PRICES_DATA_TABLE_PAGE_SIZE:
            return { ...state, pageSize: action.payload };

        case SET_PRICES_DATA_TABLE_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };

        case SET_PRICES_DATA_TABLE_EDITABLE:
            return { ...state, isEditable: action.payload };

        case SET_PRICES_DATA_TABLE_TOGGLE_COLUMNS:
            return { ...state, visibleColumnsList: action.payload }

        case SET_PRICES_DATA_PRICES_UPLOADS:
            return { ...state, priceUploads: action.payload }
        
        case SET_PRICES_DATA_REMOVE_UPLOADS:
            return { ...state, removePricesUpload: action.payload }
    
        case SET_PRICES_TABLE_DATA_LOADING:
            return { ...state, isTableDataLoading: action.payload };

        default:
            return state;
    }

}
export default priceStateReducer;

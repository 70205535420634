import { monthsUpcoming } from "./constants"

export const monthlyPlanTableColumnsList = [
    { key: "CountryCode", label: "Country Code", hidden: true },
    { key: "MainArticleGroup", label: "Main Article Group", hidden: true },
    { key: "CountryName", label: "Country", hidden: true },
    { key: "MetricName", label: "Metric", hidden: false },
    { key: "StarAsin", label: "Asin", hidden: true },
    { key: "Year", label: "Year", hidden: false },
    { key: "Jan", label: "Jan", hidden: !monthsUpcoming.includes('Jan') },
    { key: "Feb", label: "Feb", hidden: !monthsUpcoming.includes('Feb') },
    { key: "Mar", label: "Mar", hidden: !monthsUpcoming.includes('Mar') },
    { key: "Apr", label: "Apr", hidden: !monthsUpcoming.includes('Apr') },
    { key: "May", label: "May", hidden: !monthsUpcoming.includes('May') },
    { key: "Jun", label: "Jun", hidden: !monthsUpcoming.includes('Jun') },
    { key: "Jul", label: "Jul", hidden: !monthsUpcoming.includes('Jul') },
    { key: "Aug", label: "Aug", hidden: !monthsUpcoming.includes('Aug') },
    { key: "Sep", label: "Sep", hidden: !monthsUpcoming.includes('Sep') },
    { key: "Oct", label: "Oct", hidden: !monthsUpcoming.includes('Oct') },
    { key: "Nov", label: "Nov", hidden: !monthsUpcoming.includes('Nov') },
    { key: "Dec", label: "Dec", hidden: !monthsUpcoming.includes('Dec') }
];

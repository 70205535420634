import {
    SET_ACCESS_CONTROL_DETAILS,
    SET_LOGIN_AS_PER_ACCESS_CONTROL,
    SET_ACCESS_SCREEN,
    SET_ACCESS_COUNTRY
} from "../actions/access-control-action"

const initialState = {
    accessControlDetails: {
        CountryId: null,
        CategoryId: null,
        Year: null,
        MetricId: null
    },
    accessScreen: [], //For Monthly Plan - 'Monthly', For Weekly Plan - 'Weekly'
    selectedCountryOnAccess: {},
    isLoggedInAsPerAccess: false,
}


export const accessControlReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCESS_CONTROL_DETAILS:
            return { ...state, accessControlDetails: action.payload };

        case SET_LOGIN_AS_PER_ACCESS_CONTROL:
            return { ...state, isLoggedInAsPerAccess: action.payload };

        case SET_ACCESS_SCREEN:
            return { ...state, accessScreen: action.payload };

        case SET_ACCESS_COUNTRY:
            return { ...state, selectedCountryOnAccess: action.payload };

        default:
            return state;
    }
}

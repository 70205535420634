import React from 'react'
import { Col, Layout, Row, Space, Typography } from "antd"
import image from "./../../assets/Images/philips-white-logo.svg";
import { ButtonComponent } from "../../components/Button";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequests } from "../../config/msalConfig";
import { retrieveProfileData } from "../../services/msal-service";
import moment from "moment";

const { Title } = Typography;

const UnauthorizedUser = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const signInToAzure = () => {
        instance.loginRedirect(loginRequests).then(() => {
            return retrieveProfileData(instance, accounts)
        })
            .catch((e) => {
                console.log(e);
            });
    }

    const requestToolAccess = () => {
        return;
    }
    return (
        <Layout className="layout">
            <Header
                style={{
                    display: 'flex',
                    padding: '0 15px',
                    alignItems: 'center',
                    zIndex: 10001
                }}
            >
                <Row align='middle' justify='space-between'>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center', margin: '8px 4px' }}>
                        <img src={image} alt="Philips logo" style={{ width: 'auto', height: '3.5em', marginRight: '1em', marginTop: '0em', display: 'inline-block' }} />
                        <Title level={2} className='theme-heading-font'
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                                display: 'inline-block',
                                fontSize: '1.4em',
                                letterSpacing: '1.2px',
                                margin: '2px'
                            }} >Planning Tool</Title>
                    </Col>
                </Row>
            </Header>
            <Content
                style={{
                    padding: '0 20px',
                    minHeight: `calc(90dvh - 72px)`,
                    flex: 1
                }}
            >
                <Space wrap={true} size={'small'} direction="vertical" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Title level={2}>Welcome to Amazon Planning Tool!!</Title>
                    {!isAuthenticated ? (
                        <>
                            <Title level={4}>Please Sign in to access the application. </Title>
                            <ButtonComponent tooltipText='Sign in to Azure to access the application'
                                onClick={signInToAzure} loading={null} style={{ background: '#0066a1' }}
                                themeType="nav" title="Sign In" />
                        </>
                    ) : (
                        <>
                            <Title level={4}>It Seem's like you dont have access to the tool </Title>
                            <ButtonComponent tooltipText='Request Application Access'
                                onClick={requestToolAccess} loading={null} style={{ background: '#0066a1' }}
                                themeType="nav" title="Request Access" />
                        </>
                    )}
                </Space>
            </Content>
            <Footer className='app-theme'
                style={{
                    textAlign: 'center',
                    minHeight: '62px',
                    zIndex:10001
                }}
            >
                <Title level={5} style={{color:'#fff'}}>Philips ©{moment().year()}</Title>
            </Footer>
        </Layout>
    )
}

export default UnauthorizedUser;

import { DEFAULT_TABLE_PAGE_SIZE } from '../../utilities/constants';
import { targetsTableColumnsList } from '../../utilities/targets';
import {
    SET_TARGETS_DATA_TABLE_DEFAULT_FILTERS,
    SET_TARGETS_DATA_TABLE_SELECTED_FILTERS,
    SET_TARGETS_USER_SELECTED_EVENT_DETAILS,
    SET_TARGETS_DATA,
    SET_TARGETS_DATA_COPY,
    SET_TARGETS_DATA_TOTAL_RECORD_COUNT,
    SET_TARGETS_DATA_TABLE_PAGE_SIZE,
    SET_TARGETS_DATA_TABLE_CURRENT_PAGE,
    SET_TARGETS_DATA_TABLE_TOGGLE_COLUMNS,
    SET_TARGETS_DATA_TABLE_CATEGORY_FILTER_LIST,
    SET_TARGETS_DATA_TABLE_EDIT,
    SET_TARGETS_DATA_TABLE_ROW_ADDED,
    SET_TARGETS_DATA_TABLE_RESET,
    SET_TARGETS_DATA_TABLE_CANCEL_UPDATES,
    SET_TARGETS_DATA_TARGET_UPLOADS,
    SET_TARGETS_DATA_TABLE_DATES_COLUMNS_LIST,
    SET_TARGETS_UPLOADABLE_DATES_COLUMNS_LIST,
    SET_TARGETS_DATA_TABLE_SELECTED_ROWS,
    SET_TARGETS_TABLE_DATA_LOADING
} from '../actions/targets-action';

const initialState = {
    defaultFilters: { EventId: [], Year: '', DateDiff: 0, CategoryId:[], DealTypeId: [], Sku: null, Asin: null },
    userSelectedEvent: { EventId: 0, Event: '', StartDate: '', EndDate: '', DateDiff: 0 },
    targetsData: { data: [], isLoading: false },
    targetsDataClone: { displayData: [], isLoading: false },
    totalRecordCount: 1,
    dataTableCategoryFilterList: [],
    dataTableAppliedFilters: { DealTypeId: null, Sku: null, Asin: null },
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    currentPage: 1,
    isEditable: false,
    isRowAdded: false,
    visibleColumnsList: targetsTableColumnsList,
    targetDatesAsTableColumns: [],
    uploadableDatesList: [],
    uploadTarget: { isTemplateDownloaded: false, details: {} },
    rowsSelected: [],
    error: null,
    isTableDataLoading: false
}

export const targetsReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_TARGETS_DATA_TABLE_DEFAULT_FILTERS:
            return { ...state, defaultFilters: action.payload };

        case SET_TARGETS_DATA_TABLE_SELECTED_FILTERS:
            return { ...state, dataTableAppliedFilters: action.payload };

        case SET_TARGETS_USER_SELECTED_EVENT_DETAILS:
            return { ...state, userSelectedEvent: action.payload };

        case SET_TARGETS_DATA:
            return { ...state, targetsData: action.payload };

        case SET_TARGETS_DATA_COPY:
            return { ...state, targetsDataClone: action.payload };

        case SET_TARGETS_DATA_TOTAL_RECORD_COUNT:
            return { ...state, totalRecordCount: action.payload };

        case SET_TARGETS_DATA_TABLE_CATEGORY_FILTER_LIST:
            return { ...state, dataTableCategoryFilterList: action.payload };

        case SET_TARGETS_DATA_TABLE_PAGE_SIZE:
            return { ...state, pageSize: action.payload };

        case SET_TARGETS_DATA_TABLE_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };

        case SET_TARGETS_DATA_TABLE_EDIT:
            return { ...state, isEditable: action.payload };

        case SET_TARGETS_DATA_TABLE_ROW_ADDED:
            return { ...state, isRowAdded: action.payload };

        case SET_TARGETS_DATA_TABLE_RESET:
            return { ...state, isEditable: action.payload };

        case SET_TARGETS_DATA_TABLE_CANCEL_UPDATES:
            return { ...state, isEditable: action.payload };

        case SET_TARGETS_DATA_TABLE_TOGGLE_COLUMNS:
            return { ...state, visibleColumnsList: action.payload }

        case SET_TARGETS_DATA_TABLE_DATES_COLUMNS_LIST:
            return { ...state, targetDatesAsTableColumns: action.payload };
        case SET_TARGETS_UPLOADABLE_DATES_COLUMNS_LIST:
            return { ...state, uploadableDatesList: action.payload };

        case SET_TARGETS_DATA_TARGET_UPLOADS:
            return { ...state, uploadTarget: action.payload }

        case SET_TARGETS_DATA_TABLE_SELECTED_ROWS:
            return { ...state, rowsSelected: action.payload };

        case SET_TARGETS_TABLE_DATA_LOADING:
            return { ...state, isTableDataLoading: action.payload };

        default:
            return state;
    }
};

import { cloneDeep } from "lodash";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../utilities/constants";

export const SET_WEEKLY_PLAN_DATA_TABLE_DEFAULT_FILTERS = 'SET_WEEKLY_PLAN_DATA_TABLE_DEFAULT_FILTERS';
export const SET_WEEKLY_PLAN_DATA_TABLE_SELECTED_FILTERS = 'SET_WEEKLY_PLAN_DATA_TABLE_SELECTED_FILTERS';
export const SET_WEEKLY_PLAN_DATA = 'SET_WEEKLY_PLAN_TABLE_DATA';
export const SET_WEEKLY_PLAN_DATA_COPY = 'SET_WEEKLY_PLAN_DATA_COPY';
export const SET_WEEKLY_PLAN_DATA_TOTAL_RECORD_COUNT = 'SET_WEEKLY_PLAN_DATA_TOTAL_RECORD_COUNT';
export const SET_WEEKLY_PLAN_DATA_TABLE_PAGE_SIZE = 'SET_WEEKLY_PLAN_DATA_TABLE_PAGE_SIZE';
export const SET_WEEKLY_PLAN_DATA_TABLE_CURRENT_PAGE = 'SET_WEEKLY_PLAN_DATA_TABLE_CURRENT_PAGE';
export const SET_WEEKLY_PLAN_DATA_TABLE_TOGGLE_COLUMNS = 'SET_WEEKLY_PLAN_DATA_TABLE_TOGGLE_COLUMNS';
export const SET_WEEKLY_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST = 'SET_WEEKLY_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST';
export const SET_WEEKLY_PLAN_CATEGORY_LIST = 'SET_WEEKLY_PLAN_CATEGORY_LIST';
export const SET_WEEKLY_PLAN_DATA_TABLE_METRIC_FILTER_LIST = 'SET_WEEKLY_PLAN_DATA_TABLE_METRIC_FILTER_LIST';
export const SET_WEEKLY_PLAN_DATA_TABLE_EDIT= 'SET_WEEKLY_PLAN_DATA_TABLE_EDIT';
export const SET_WEEKLY_PLAN_DATA_TABLE_RESET= 'SET_WEEKLY_PLAN_DATA_TABLE_RESET';
export const SET_WEEKLY_PLAN_DATA_TABLE_CANCEL_UPDATES= 'SET_WEEKLY_PLAN_DATA_TABLE_CANCEL_UPDATES';
export const SET_WEEKLY_PLAN_DATA_ADD_SKU_UPLOADS = 'SET_WEEKLY_PLAN_DATA_ADD_SKU_UPLOADS';
export const SET_WEEKLY_PLAN_TABLE_DATA_LOADING = 'SET_WEEKLY_PLAN_TABLE_DATA_LOADING';
export const SET_WEEKLY_PLAN_DATA_REMOVE_SKU_UPLOADS = 'SET_WEEKLY_PLAN_DATA_REMOVE_SKU_UPLOADS';

export const setWeeklyPlanDefaultFilters = (filters) => ({
    type:SET_WEEKLY_PLAN_DATA_TABLE_DEFAULT_FILTERS,
    payload:cloneDeep(filters)
})

export const setWeeklyPlanDataTableAppliedFilters = (filters) => ({
    type:SET_WEEKLY_PLAN_DATA_TABLE_SELECTED_FILTERS,
    payload:cloneDeep(filters)
})

export const setWeeklyPlanData = (data = [], isLoadingBoolValue = false) => ({
        type:SET_WEEKLY_PLAN_DATA,
        payload:{data:cloneDeep(data), isLoading:isLoadingBoolValue}
})

export const setWeeklyPlanDataClone = (data = [], isLoadingBoolValue = false) => ({
    type:SET_WEEKLY_PLAN_DATA_COPY,
    payload:{displayData:cloneDeep(data), isLoading:isLoadingBoolValue}
})

export const setWeeklyPlanCategoryList = (catList = []) => ({
    type:SET_WEEKLY_PLAN_CATEGORY_LIST,
    payload:cloneDeep(catList)
})

export const setWeeklyDataTableCategoryFilterList = (catList = []) => ({
    type:SET_WEEKLY_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST,
    payload:cloneDeep(catList)
})

export const setWeeklyDataTableMetricFilterList = (metricList = []) => ({
    type:SET_WEEKLY_PLAN_DATA_TABLE_METRIC_FILTER_LIST,
    payload:cloneDeep(metricList)
})

export const setWeeklyDataTableEditable = (editEnabled = false) => ({
    type:SET_WEEKLY_PLAN_DATA_TABLE_EDIT,
    payload:cloneDeep(editEnabled)
})

export const setWeeklyDataTableResetData = (editEnabled = true) => ({
    type:SET_WEEKLY_PLAN_DATA_TABLE_RESET,
    payload:cloneDeep(editEnabled)
})

export const setWeeklyDataCancelUpdates = (editEnabled = true) => ({
    type:SET_WEEKLY_PLAN_DATA_TABLE_CANCEL_UPDATES,
    payload:cloneDeep(editEnabled)
})

export const setWeeklyDataTotalRecordCount = (recordCount = 0) => ({
    type:SET_WEEKLY_PLAN_DATA_TOTAL_RECORD_COUNT,
    payload:cloneDeep(recordCount)
})

export const setWeeklyPlanDataTablePageSize = (displayRecordCount = DEFAULT_TABLE_PAGE_SIZE) => ({
    type:SET_WEEKLY_PLAN_DATA_TABLE_PAGE_SIZE,
    payload:cloneDeep(displayRecordCount)
})

export const setWeeklyPlanDataTableCurrentPage = (displayCurrentPage = 1) => ({
    type:SET_WEEKLY_PLAN_DATA_TABLE_CURRENT_PAGE,
    payload:cloneDeep(displayCurrentPage)
})

export const setWeeklyPlanDataTableVisibleColumnsList = (columnsList = []) => ({
    type:SET_WEEKLY_PLAN_DATA_TABLE_TOGGLE_COLUMNS,
    payload:cloneDeep(columnsList)
})

export const setWeeklyPlanAddSkuUploads = (bool = false) => ({
    type:SET_WEEKLY_PLAN_DATA_ADD_SKU_UPLOADS,
    payload:bool
})

export const setWeeklyPlanTableDataLoading = (bool = false) => ({
    type:SET_WEEKLY_PLAN_TABLE_DATA_LOADING,
    payload:bool
})

export const setWeeklyPlanRemoveSkuUploads = (bool) => ({
    type:SET_WEEKLY_PLAN_DATA_REMOVE_SKU_UPLOADS,
    payload:bool
})

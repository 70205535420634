import React from 'react';
import { Button, Space, Tooltip } from 'antd';

export const ButtonComponent = (props) => {
    const { title = 'Button', tooltipText = '', loading = null, type, themeType = 'nav', onClickHandle, ...rest } = props;
    const buttonStype = themeType === 'nav' ? {
        opacity: 1,
        borderRadius: '3.5px',
        color: '#fff',
        fontSize: '16px',
        padding: '6px 8px',
        minWidth: 130
    } : {
        backgroundColor: '#0074e0', color: '#fff', fontSize: '14px',
        padding: '6px 8px', border: 'none', minWidth: 130,
        margin: '0px 0px 1px 0px', borderRadius: '3.5px',
        boxShadow: '3px 2px 2px 2px grey', cursor: 'pointer'
    }
    return (
        <Space wrap={true}>
            <Tooltip title={tooltipText} placement="bottom">
                <Button
                    loading={(loading === null) ? null : loading}
                    type={(type == 'Button' || type == null) ? "primary" : type}
                    onClick={() => onClickHandle()}
                    size={'middle'}
                    style={buttonStype}
                    {...rest}>
                    {title}
                </Button>
            </Tooltip>
        </Space>
    )
}
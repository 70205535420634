export const SET_ACCESS_CONTROL_DETAILS = 'SET_ACCESS_CONTROL_DETAILS';
export const SET_LOGIN_AS_PER_ACCESS_CONTROL = 'SET_LOGIN_AS_PER_ACCESS_CONTROL';
export const SET_ACCESS_SCREEN = 'SET_ACCESS_SCREEN';
export const SET_ACCESS_COUNTRY = 'SET_ACCESS_COUNTRY';

export const setAccessControlDetails = (accessDetails = {}) => ({
    type:SET_ACCESS_CONTROL_DETAILS,
    payload: accessDetails
});

export const setUserLogInState = (accessBoolValue = {}) => ({
    type:SET_LOGIN_AS_PER_ACCESS_CONTROL,
    payload: accessBoolValue
});

export const setUserAccessScreens = (accessScreen = 'Monthly') => ({
    type:SET_ACCESS_SCREEN,
    payload: accessScreen
});

export const setSelectedCountryOnAccess = (selectedCountryObj = {}) => ({
    type:SET_ACCESS_COUNTRY,
    payload: selectedCountryObj
})
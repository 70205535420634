
export const SET_MONTHLY_PLAN_SUMMARY_API_REFRESH = 'SET_MONTHLY_PLAN_SUMMARY_API_REFRESH';
export const SET_MONTHLY_PLAN_CURRENT_YEAR_SUMMARY = 'SET_MONTHLY_PLAN_CURRENT_YEAR_SUMMARY';
export const SET_MONTHLY_PLAN_LAST_YEAR_SUMMARY = 'SET_MONTHLY_PLAN_LAST_YEAR_SUMMARY';
export const SET_MONTHLY_PLAN_DIFF_CURRENT_LAST_SUMMARY = 'SET_MONTHLY_PLAN_DIFF_CURRENT_LAST_SUMMARY';

const initialFSDataSets = {
    currentYear: { data: [], isLoading: false },
    lastYear: { data: [], isLoading: false },
    diffCurrentVsLast: { data: [], isLoading: false },
}

export const setMonthlyPlanFinancialSummaryApiTrigger = (hasFinancialSummaryData = false) => ({
    type: SET_MONTHLY_PLAN_SUMMARY_API_REFRESH,
    payload: hasFinancialSummaryData
})

export const setMonthlyPlanCurrentYearFinancialSummary = (data = initialFSDataSets) => ({
    type:SET_MONTHLY_PLAN_CURRENT_YEAR_SUMMARY,
    payload:data
})

export const setMonthlyPlanLastYearFinancialSummary = (data = initialFSDataSets) => ({
    type:SET_MONTHLY_PLAN_LAST_YEAR_SUMMARY,
    payload:data
})

export const setMonthlyPlanDiffCurrentVsaAstFinancialSummary = (data = initialFSDataSets) => ({
    type:SET_MONTHLY_PLAN_DIFF_CURRENT_LAST_SUMMARY,
    payload:data
})

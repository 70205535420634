import moment from "moment"

export const dealPlanTableColumnsList = [
    { key: "Sku", label: "SKU", hidden: false },
    { key: "BusinessUnitDesc", label: "Philips Category", hidden: false },
    { key: "MainArticleGroup", label: "Main Article Group", hidden: true },
    { key: "DealType", label: "Deal Type", hidden: false },
    { key: "DealStartDate", label: "Deal Start Date", hidden: false },
    { key: "DealEndDate", label: "Deal End Date", hidden: false },
    { key: "StarAsin", label: "ASIN", hidden: true },
    { key: "SoaPerUnit", label: "SOA / Unit", hidden: false },
    { key: "PlannedUnits", label : "PlannedUnits", hidden: false },
    { key: "Event", label: "Event", hidden: false },
    { key: "PeakEventFlag", label: "Peak Event Flag", hidden: false },
    { key: "DealStatus", label: "Deal Status", hidden: false },
    { key: "AmazonAgreementNumber", label: "Amazon Agreement Number", hidden: true },
    { key: "Comments", label: "Comments", hidden: false },
    { key: "TripleNetPrice", label: "Triple Net Price", hidden: true },
    { key: "NetInvoicePrice", label: "Net Invoice from Price List", hidden: false },
    { key: "AmazonCategory", label: "Amazon Category", hidden: false },
    { key: "CountryCode", label: "Country Code", hidden: true },
]

export const dealPlanAdditionalColumns = [
    { key: "Week", label: "Week", hidden: true },
    { key: "Month", label: "Month", hidden: true },
    { key: "Year", label: "Year", hidden: true },
    { key: "SoaOfNi", label: "SOA of NI", hidden: true },
    { key: "PlannedSoaInvestment", label: "Planned SOA Investment", hidden: true },
    { key: "ActualSoaInvestment", label: "Actual SOA Investment", hidden: true },
    { key: "GapPlannedVsActual", label: "Gap Planned vs Actual", hidden: true },
    { key: "ActualUnitsSold", label: "Actual Units Sold", hidden: false },
    { key: "SoaAccuralSap", label: "SOA accural Sap", hidden: true },
    { key: "DeviationSoaVsSoaAccural", label: "Deviation - Soa vs Soa accural", hidden: true },
    { key: "CountryName", label: "Country", hidden: true },
    { key: "ProductGroup", label: "Amazon GL", hidden: true },
]


export const dealPlanUSColumns = [
    { key: "WeekStart", label: "Week Start", hidden: false },
    { key: "WeekEnd", label: "Week End", hidden: false },
    { key: "Year", label: "Year", hidden: true },
    { key: "BaseUnits", label: "Base Units", hidden: false },
    { key: "DotdUnits", label: "DOTD Units", hidden: false },
    { key: "StandardPromotionUnits", label: "Standard Promotion Units", hidden: false },
    { key: "MediaUnits", label: "Media Boost Units", hidden: false },
    { key: "TotalPromotionUnits", label: "Total Promotion Units", hidden: true },
    { key: "DotdFunding", label: "DOTD Funding", hidden: false },
    { key: "StandardPromotionFunding", label: "Standard Promotion Funding", hidden: false },
    { key: "MediaFunding", label: "Media Boost Funding", hidden: false },
    { key: "TotalPromotionFunding", label: "Total Promotion Funding", hidden: true },
]

export const dealPlanNewRow = {
    ID: 0,
    DealId: "",
    CountryId: 0,
    CountryCode: "",
    CountryName: "",
    StockId: 0,
    Sku: "",
    StarAsin: "",
    CategoryId: 0,
    BusinessUnit: "",
    BusinessUnitDesc: "",
    Mag: "",
    Ag: "",
    AmazonGl: "",
    AmazonCategoryId: 0,
    AmazonCategory: "",
    EventId: 0,
    Event: "",
    PeakEventFlagId: 0,
    PeakEventFlag: "",
    DealTypeId: 0,
    DealType: "",
    DealStatusId: 0,
    DealStatus: "",
    AmazonAgreementNumber: "",
    DealStartDate: moment().format("DD-MMM-YYYY"),
    DealEndDate: moment().add(1, "days").format("DD-MMM-YYYY"),
    TripleNetPrice: 0,
    NetInvoicePrice: 0,
    SoaPerUnit: 0,
    Week: moment().week(),
    Month: moment().format("MMMM"),
    Year: moment().year(),
    Comments: "",
    InsertTimestamp: moment().date().toLocaleString(),
    isAdded: false,
    isModified: false,
    isDeleted: false
}

export const dealPlanNewRowAdditionalColumns = {
    PlannedUnits: 0,
    SoaOfNi: 0,
    PlannedSoaInvestment: 0,
    ActualSoaInvestment: 0,
    GapPlannedVsActual: 0,
    ActualUnitsSold: 0,
    SoaAccrualSap: 0,
    DeviationSoaVsSoaAccural: 0,
    DealCreatedBy: 0,
    DealUpdatedBy: 0
};

import { DEFAULT_TABLE_PAGE_SIZE } from '../../utilities/constants';
import {
    SET_DEAL_PLAN_SUMMARY_DATA,
    SET_DEAL_PLAN_SUMMARY_API_REFRESH,
    SET_DEAL_SUMMARY_DATA_TABLE_CURRENT_PAGE,
    SET_DEAL_SUMMARY_DATA_TABLE_PAGE_SIZE,
    SET_DEAL_SUMMARY_DATA_TOTAL_RECORD_COUNT,
    SET_DEAL_SUMMARY_DROPDOWN_SELECTED_FILTERS,
    SET_DEAL_SUMMARY_DATA_TABLE_DEFAULT_FILTERS,
    SET_DEAL_PLAN_SUMMARY_DATA_CLONE,
    SET_DEAL_SUMMARY_TABLE_DATA_LOADING
} from '../actions/summary-deal-plan-action';

const initialState = {
    defaultFilters: { CategoryId: [], Year: '', },
    dropdownAppliedFilters: { CategoryId: null, AmazonCategoryId: null, DealEventId: null, DealTypeId: null, MonthId: null, WeekId: null },
    dealPlanSummaryData: { data: [], isLoading: false },
    dealPlanSummaryDataClone: { displayData: [], isLoading: false },
    totalRecordCount: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    currentPage: 1,
    error: null,
    isApiRefresh: false,
    isTableDataLoading: false
}

export const dealSummaryReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_DEAL_SUMMARY_DATA_TABLE_DEFAULT_FILTERS:
            return { ...state, defaultFilters: action.payload };

        case SET_DEAL_SUMMARY_DROPDOWN_SELECTED_FILTERS:
            return { ...state, dropdownAppliedFilters: action.payload };

        case SET_DEAL_PLAN_SUMMARY_API_REFRESH:
            return { ...state, isApiRefresh: action.payload }

        case SET_DEAL_PLAN_SUMMARY_DATA:
            return { ...state, dealPlanSummaryData: action.payload }

        case SET_DEAL_PLAN_SUMMARY_DATA_CLONE:
            return { ...state, dealPlanSummaryDataClone: action.payload }

        case SET_DEAL_SUMMARY_DATA_TABLE_PAGE_SIZE:
            return { ...state, pageSize: action.payload };

        case SET_DEAL_SUMMARY_DATA_TABLE_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };

        case SET_DEAL_SUMMARY_DATA_TOTAL_RECORD_COUNT:
            return { ...state, totalRecordCount: action.payload };

        case SET_DEAL_SUMMARY_TABLE_DATA_LOADING:
            return { ...state, isTableDataLoading: action.payload };

        default:
            return state;
    }
}
export default dealSummaryReducer;

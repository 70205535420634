import {
    SET_DISPLAY_CATEGORIES, SET_DISPLAY_DETAILS,
    SET_USER_DETAILS, SET_USER_COUNTRY_ACCESS_DETAILS
} from "../actions/users"

const initialState = {
    userDetails: {
        data: [],
        userDetails: {
            Email: '',
            UserName: "",
            UserId: "",
            Countries: [],
            hasAdminAccess:false
        },
        Countries: [],
        targetCountries: [],
        dealplannerCountries: [],
        monthlyplannerCountries: [],
        weeklyplannerCountries: [],
        pricesCountries:[],
        hasAdminAccess:false
    },
    displayDetails: { UserName: '', CountryCode: '' },
    accessDetails: { data: [] },
    userCategories: []
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DETAILS:
            return { ...state, userDetails: action.payload }

        case SET_DISPLAY_DETAILS:
            return { ...state, displayDetails: action.payload }

        case SET_DISPLAY_CATEGORIES:
            return {
                ...state,
                userCategories: action.payload
            }
        case SET_USER_COUNTRY_ACCESS_DETAILS:
            return {
                ...state,
                accessDetails: action.payload
            }
        default:
            return { ...state }
    }
}
export default userReducer;

import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, isAuthenticated, ...rest }) => {
    return isAuthenticated ? (
        children
    ) : (
        <Navigate to="/login" replace={true} />
    );
};

export default ProtectedRoute;
import { DEFAULT_TABLE_PAGE_SIZE } from '../../utilities/constants';
import { dealPlanTableColumnsList } from '../../utilities/deal-plan';
import {
    SET_DEAL_PLAN_DATA,
    SET_DEAL_PLAN_DATA_COPY,
    SET_DEAL_PLAN_DATA_TABLE_CURRENT_PAGE,
    SET_DEAL_PLAN_DATA_TABLE_SELECTED_FILTERS,
    SET_DEAL_PLAN_DATA_TABLE_PAGE_SIZE,
    SET_DEAL_PLAN_DATA_TOTAL_RECORD_COUNT,
    SET_DEAL_PLAN_DATA_TABLE_TOGGLE_COLUMNS,
    SET_DEAL_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST,
    SET_DEAL_PLAN_DATA_TABLE_EDIT,
    SET_DEAL_PLAN_DATA_TABLE_DEFAULT_FILTERS,
    SET_DEAL_PLAN_DATA_TABLE_DEAL_TYPE_FILTER_LIST,
    SET_DEAL_PLAN_DATA_TABLE_DEAL_STATUS_FILTER_LIST,
    SET_DEAL_PLAN_DATA_TABLE_PEAK_EVENT_FLAG_FILTER_LIST,
    SET_DEAL_PLAN_DATA_TABLE_EVENT_FILTER_LIST,
    SET_DEAL_PLAN_UPLOAD_DEAL_DOWNLOAD_TEMPLATE,
    SET_DEAL_PLAN_DATA_TABLE_ADD_ROW,
    SET_DEAL_PLAN_DATA_TABLE_SELECTED_ROWS,
    SET_DEAL_PLAN_TABLE_DATA_LOADING
} from '../actions/deal-plan-actions';

const initialState = {
    defaultFilters: { Year: '', CategoryId: [], AmazonCategoryId: [], DealTypeId: [], DealStatusId: [], PeakEventFlagId: [], EventId: [], Sku: "", StarAsin: "" },
    plannedData: { data: [], isLoading: false },
    plannedDataClone: { displayData: [], isLoading: false },
    totalRecordCount: 1,
    dataTableCategoryFilterList: [],
    dataTableDealTypeFilterList: [],
    dataTableDealStatusFilterList: [],
    dataTablePeakEventFlagFilterList: [],
    dataTableEventFilterList: [],
    dataTableAppliedFilters: { AmazonCategoryId: null, DealTypeId: null, DealStatusId: null, PeakEventFlagId: null, EventId: null, Sku: null, StarAsin: null },
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    addDealUploads: { isTemplateDownloaded: false, details: {} },
    currentPage: 1,
    isEditable: { editEnabled: false },
    isRowAdded: false,
    rowsSelected: [],
    visibleColumnsList: dealPlanTableColumnsList,
    isTableDataLoading: false
}

const dealPlanReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_DEAL_PLAN_DATA_TABLE_DEFAULT_FILTERS:
            return { ...state, defaultFilters: action.payload };

        case SET_DEAL_PLAN_DATA:
            return { ...state, plannedData: action.payload };

        case SET_DEAL_PLAN_DATA_COPY:
            return { ...state, plannedDataClone: action.payload };

        case SET_DEAL_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST:
            return { ...state, dataTableCategoryFilterList: action.payload };

        case SET_DEAL_PLAN_DATA_TABLE_DEAL_TYPE_FILTER_LIST:
            return { ...state, dataTableDealTypeFilterList: action.payload };

        case SET_DEAL_PLAN_DATA_TABLE_DEAL_STATUS_FILTER_LIST:
            return { ...state, dataTableDealStatusFilterList: action.payload };

        case SET_DEAL_PLAN_DATA_TABLE_PAGE_SIZE:
            return { ...state, pageSize: action.payload };

        case SET_DEAL_PLAN_DATA_TABLE_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };

        case SET_DEAL_PLAN_DATA_TOTAL_RECORD_COUNT:
            return { ...state, totalRecordCount: action.payload };

        case SET_DEAL_PLAN_DATA_TABLE_SELECTED_FILTERS:
            return { ...state, dataTableAppliedFilters: action.payload };

        case SET_DEAL_PLAN_DATA_TABLE_TOGGLE_COLUMNS:
            return { ...state, visibleColumnsList: action.payload }

        case SET_DEAL_PLAN_DATA_TABLE_EDIT:
            return { ...state, isEditable: action.payload };

        case SET_DEAL_PLAN_DATA_TABLE_ADD_ROW:
            return { ...state, isRowAdded: action.payload };

        case SET_DEAL_PLAN_UPLOAD_DEAL_DOWNLOAD_TEMPLATE:
            return { ...state, addDealUploads: action.payload }

        case SET_DEAL_PLAN_DATA_TABLE_PEAK_EVENT_FLAG_FILTER_LIST:
            return { ...state, dataTablePeakEventFlagFilterList: action.payload };

        case SET_DEAL_PLAN_DATA_TABLE_EVENT_FILTER_LIST:
            return { ...state, dataTableEventFilterList: action.payload };

        case SET_DEAL_PLAN_DATA_TABLE_SELECTED_ROWS:
            return { ...state, rowsSelected: action.payload };

        case SET_DEAL_PLAN_TABLE_DATA_LOADING:
            return { ...state, isTableDataLoading: action.payload };

        default:
            return state;
    }

}
export default dealPlanReducer;

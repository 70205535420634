import { Col, Layout, Row, Space, Typography } from 'antd'
import { errorMessage } from './../../utilities/constants'
import { ButtonComponent } from './../../components/Button'
import image from "./../../assets/Images/philips-white-logo.svg";
import { Content, Header } from 'antd/es/layout/layout';

const { Title } = Typography;
export const MyFallbackComponent = ({ error, resetErrorBoundary }) => {
    return (
        <Layout className="layout">
            <Header
                style={{
                    display: 'flex',
                    padding: '0 15px',
                    alignItems: 'center',
                    zIndex: 10001
                }}
            >
                <Row align='middle' justify='space-between'>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center', margin: '8px 4px' }}>
                        <img src={image} alt="Philips logo"
                            style={{
                                width: 'auto',
                                height: '3.5em',
                                marginRight: '1em',
                                marginTop: '0em',
                                display: 'inline-block'
                            }}
                        />
                        <Title level={2} className='theme-heading-font'
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                                display: 'inline-block',
                                fontSize: '1.4em',
                                letterSpacing: '1.2px',
                                margin: '2px'
                            }} >Planning Tool</Title>
                    </Col>
                </Row>
            </Header>
            <Content
                style={{
                    padding: '0 20px',
                    minHeight: `calc(99dvh)`,
                    flex: 1
                }}
            >
                <Row align='middle' justify='space-between' style={{ width: '100%' }}>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center', margin: '8px 4px' }}>
                        <Space wrap={true} size={'small'} direction="vertical" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Title level={2}>Uh-oh!</Title>
                            <Title level={4}>{errorMessage}</Title>
                            <ButtonComponent onClick={resetErrorBoundary} title={'Try Again'} loading={null}
                                style={{ background: '#0066a1' }} themeType="nav" />
                        </Space>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
import moment, { monthsShort } from "moment/moment";
import {
    SET_APP_APPLICABLE_YEARS,
    SET_OK_BUTTON_LOADING_ICON,
    SET_CATEGORY_LIST_MAPPING,
    SET_IS_DATA_MODIFIED,
    SET_FILTER_LIST,
    SET_METRIC_LIST_MAPPING,
    SET_IS_EDITABLE_METRIC_LIST_MAPPING,
    SET_ERROR,
    SET_COUNTRY_MAPPING,
    SET_DEAL_TYPE_LIST_MAPPING,
    SET_DEAL_STATUS_LIST_MAPPING,
    SET_AMAZON_CATEGORY_LIST_MAPPING,
    SET_PEAK_EVENT_FLAG_LIST_MAPPING,
    SET_EVENT_LIST_MAPPING,
    SET_EVENTS_LIST_FOR_CURRENT_YEAR,
    SET_GLOBAL_DATA_LOAD_COMPLETED,
    SET_UPLOADABLE_TARGET_EVENTS_LIST,
    SET_IS_DATA_LOADING,
    SET_MASTER_EVENT_LIST_MAPPING
} from "../actions/base-actions"
import {
    upcomingMonthsIdLableList,
    upcomingWeeksObjList
} from "../../utilities/constants";

const initialState = {
    accessTypes: [{ 1: 'Planner' }, { 2: 'Target' }],
    pages: [
        'Common', 'MonthlyPlan',
        'MonthlyPlanFinancialSummary', 'WeeklyPlanFinancialSummary',
        'DealPlan', 'WeeklyPlan', 'DealPlanSummary', 'Targets',
        'Prices', 'Admin'
    ],
    accessLevels: [{ 1: 'Country' }, { 2: 'Category' }],
    yearList: {
        planYearList: [
            { label: new Date().getFullYear(), key: new Date().getFullYear() },
            { label: new Date().getFullYear() + 1, key: new Date().getFullYear() + 1 },
            { label: new Date().getFullYear() + 2, key: new Date().getFullYear() + 2 }
        ],
        actualYearList: [
            { label: new Date().getFullYear() - 1, key: new Date().getFullYear() - 1 },
            { label: new Date().getFullYear(), key: new Date().getFullYear() },
            { label: new Date().getFullYear() + 1, key: new Date().getFullYear() + 1 },
        ],
    },
    defaultValues: {
        EuMetricNames: [
            'Planned Sell Out Units - Total (by e-com Mgr.)',
            'Planned PCOGS (Planned Units * Net Invoice Price)',
            'Net Invoice Price per Unit (from Price List)',
        ],
        NonEuMetricName: [
            "Planned Base Ordered Units",
            "Planned Conversion Rate (%)",
            "Planned Standard Promotion Ordered Units",
            "Planned Media Boost Units"
        ],
        Year: moment().year()
    },
    abbrevatedMonthList: monthsShort(),
    upcomingMonthsList: upcomingMonthsIdLableList,
    applicationEditableFields: [],
    upcomingWeeksList: upcomingWeeksObjList,
    isDataModified: false,
    countryList: { data: [], hasNext: false },
    metricList: { data: [], hasNext: false },
    categoryList: { data: [], hasNext: false },
    dealTypeList: { data: [], hasNext: false },
    dealStatusList: { data: [], hasNext: false },
    peakEventFlagList: { data: [], hasNext: false },
    eventList: { data: [], hasNext: false },
    masterEventsList: { data: [], hasNext: false },
    amazonCategoryList: { data: [], hasNext: false },
    availableCurrentYearEvents: [],
    uploadableTargetEvents: [],
    filters: {
        Common: {
            CategoryId: [],
            Year: ''
        },
        MonthlyPlan: {
            MetricId: [],
            Sku: "",
        },
        MonthlyPlanFinancialSummary: {
            CategoryId: [], Year: ''
        },
        WeeklyPlanFinancialSummary: {
            CategoryId: [], Year: ''
        },
        DealPlan: {
            AmazonCategoryId: [],
            DealTypeId: [],
            DealStatusId: [],
            PeakEventFlagId: [],
            EventId: [],
            Sku: "",
            StarAsin: ""

        },
        WeeklyPlan: {
            MetricId: [],
            Sku: "",

        },
        Targets: {
            EventId: '',
            Year: '',
            DateDiff: 0,
            DealTypeId: [],
            CategoryId: [],
            Sku: '',
            Asin: '',
        },
        DealPlanSummary: {
            CategoryId: [],
            Year: '',
            AmazonCategoryId: [],
            DealEventId:[],
            DealTypeId: [],
            MonthId: [],
            WeekId: [],

        },
        Prices: {
            CountryId:[],
            Year:'',
            Month:[],
            Sku: ''
        }
    },
    isModalOkButtonLoading: false,
    isDataLoading: false,
    globalDataLoadCompleted: false,
    errorList: {}
}

const baseReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_APP_APPLICABLE_YEARS:
            return { ...state };

        case SET_COUNTRY_MAPPING:
            return { ...state, countryList: action.payload }

        case SET_CATEGORY_LIST_MAPPING:
            return { ...state, categoryList: action.payload }

        case SET_METRIC_LIST_MAPPING:
            return { ...state, metricList: action.payload }

        case SET_IS_EDITABLE_METRIC_LIST_MAPPING:
            return { ...state, applicationEditableFields: action.payload };

        case SET_IS_DATA_MODIFIED:
            return { ...state, isDataModified: action.payload }

        case SET_FILTER_LIST:
            return { ...state, filters: action.payload }

        case SET_OK_BUTTON_LOADING_ICON:
            return { ...state, isModalOkButtonLoading: action.payload }

        case SET_IS_DATA_LOADING:
            return { ...state, isDataLoading: action.payload }

        case SET_ERROR:
            return { ...state, errorList: action.payload }

        case SET_DEAL_TYPE_LIST_MAPPING:
            return { ...state, dealTypeList: action.payload }

        case SET_DEAL_STATUS_LIST_MAPPING:
            return { ...state, dealStatusList: action.payload }

        case SET_AMAZON_CATEGORY_LIST_MAPPING:
            return { ...state, amazonCategoryList: action.payload }

        case SET_PEAK_EVENT_FLAG_LIST_MAPPING:
            return { ...state, peakEventFlagList: action.payload };

        case SET_EVENT_LIST_MAPPING:
            return { ...state, eventList: action.payload };

        case SET_EVENTS_LIST_FOR_CURRENT_YEAR:
            return { ...state, availableCurrentYearEvents: action.payload };

        case SET_UPLOADABLE_TARGET_EVENTS_LIST:
            return { ...state, uploadableTargetEvents: action.payload };

        case SET_GLOBAL_DATA_LOAD_COMPLETED:
            return { ...state, globalDataLoadCompleted: action.payload };

        case SET_MASTER_EVENT_LIST_MAPPING:
            return { ...state, masterEventsList: action.payload };

        default:
            return state;
    }
}
export default baseReducer;

import { currentYear, actualMonthRange, weeksBasedConstantsPerSelectedYear } from "./constants"
import { range } from "lodash"
import moment from "moment";


export const getWeeksInfoForSelectedYear = (selectedYear = currentYear, weekDay = 0 /**considering sunday as start day of week*/ ) => {
    const weeks = [];
    moment.updateLocale('en', { week: { dow: weekDay } });
    const lastDateOfYear = moment().year(selectedYear).endOf('year')

    actualMonthRange.map(monthNumber => {
        const monthStartDate = moment([selectedYear, monthNumber - 1, 1]);
        const monthEndDate = moment([selectedYear, monthNumber - 1]).endOf('month');
        
        let weekStart = monthStartDate.clone().startOf('week');
        let weekEnd = monthStartDate.clone().endOf('week');
        while (weekStart <= monthEndDate && weekEnd.month() <= monthNumber - 1 && moment(lastDateOfYear).diff(weekStart, 'days') >= 3 ) {
            const weekNumber = weekEnd.week();
            const actualMonthNumber = weekEnd.month() + 1;
            const monthString = weekEnd.format('MMMM');
            weeks.push({
                'weekNumber': weekNumber,
                'weekStartDate': weekStart.format('YYYY-MM-DD'),
                'weekEndDate': weekEnd.format('YYYY-MM-DD'),
                'monthNumber': actualMonthNumber,
                'monthString': monthString
            });
            weekStart.add(1, 'week');
            weekEnd.add(1, 'week');
        }
    })
    return weeks
}

export const getDayDateOfallWeeksOfAnYear = (weekDay = 1, selectedYear = currentYear) => {
    if(weekDay > 8 || weekDay < 1){
        return []
    }
    const dayDateArray = [];
    for (let week = 1; week <= 52; week++) {
        const saturdayDate = moment().year(Number(selectedYear)).isoWeek(week).isoWeekday(weekDay);
        dayDateArray.push({
            'WeekNumber': Number(week),
            'WeekString': `Week${week}`,
            'DayName': moment(new Date(saturdayDate),"YYYY-MM-DD").format('dddd'),
            'Date':saturdayDate.format("YYYY-MM-DD"),
            'DisplayFormat':saturdayDate.format("MMM-DD-YYYY")
            }
        );
    }
    return dayDateArray
}


export const weeklyPlanTableColumnsList = (selectedYear = currentYear) => {
    const totalWeeks = getWeeksInfoForSelectedYear(Number(selectedYear)).length
    return [
        { key: "CountryCode", label: "Country Code", hidden: true },
        
        { key: "MetricName", label: "Metric", hidden: false },
        { key: "StarAsin", label: "Asin", hidden: true },
        { key: "Year", label: "Year", hidden: false },
        ...(range(1, totalWeeks + 1 , 1).map(i => ({
            key: `Week${i}`, label: `Week ${i}`, hidden: weeksBasedConstantsPerSelectedYear(selectedYear).weeksUpcomingNumberList.includes(i) ? false : true
        })
        )),
        { key: "MainArticleGroup", label: "Main Article Group", hidden: true },
        { key: "CountryName", label: "Country", hidden: true },
    ]
}

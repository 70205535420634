import { cloneDeep } from "lodash";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../utilities/constants";

export const SET_DEAL_PLAN_DATA = 'SET_DEAL_PLAN_TABLE_DATA';
export const SET_DEAL_PLAN_DATA_TABLE_DEFAULT_FILTERS = 'SET_DEAL_PLAN_DATA_TABLE_DEFAULT_FILTERS';
export const SET_DEAL_PLAN_DATA_TABLE_SELECTED_FILTERS = 'SET_DEAL_PLAN_DATA_TABLE_SELECTED_FILTERS';
export const SET_DEAL_PLAN_DATA_COPY = 'SET_DEAL_PLAN_DATA_COPY';
export const SET_DEAL_PLAN_DATA_TOTAL_RECORD_COUNT = 'SET_DEAL_PLAN_DATA_TOTAL_RECORD_COUNT';
export const SET_DEAL_PLAN_DATA_TABLE_TOGGLE_COLUMNS = 'SET_DEAL_PLAN_DATA_TABLE_TOGGLE_COLUMNS';
export const SET_DEAL_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST = 'SET_DEAL_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST';
export const SET_DEAL_PLAN_CATEGORY_LIST = 'SET_DEAL_PLAN_CATEGORY_LIST';
export const SET_DEAL_PLAN_DATA_TABLE_DEAL_TYPE_FILTER_LIST = 'SET_DEAL_PLAN_DATA_TABLE_DEAL_TYPE_FILTER_LIST';
export const SET_DEAL_PLAN_DATA_TABLE_DEAL_STATUS_FILTER_LIST = 'SET_DEAL_PLAN_DATA_TABLE_DEAL_STATUS_FILTER_LIST';
export const SET_DEAL_PLAN_DATA_TABLE_PAGE_SIZE = 'SET_DEAL_PLAN_DATA_TABLE_PAGE_SIZE';
export const SET_DEAL_PLAN_DATA_TABLE_CURRENT_PAGE = 'SET_DEAL_PLAN_DATA_TABLE_CURRENT_PAGE';
export const SET_DEAL_PLAN_DATA_TABLE_EDIT= 'SET_DEAL_PLAN_DATA_TABLE_EDIT';
export const SET_DEAL_PLAN_DATA_TABLE_ADD_ROW= 'SET_DEAL_PLAN_DATA_TABLE_ADD_ROW';
export const SET_DEAL_PLAN_DATA_TABLE_PEAK_EVENT_FLAG_FILTER_LIST = 'SET_DEAL_PLAN_DATA_TABLE_PEAK_EVENT_FLAG_FILTER_LIST';
export const SET_DEAL_PLAN_DATA_TABLE_EVENT_FILTER_LIST = 'SET_DEAL_PLAN_DATA_TABLE_EVENT_FILTER_LIST';
export const SET_DEAL_PLAN_UPLOAD_DEAL_DOWNLOAD_TEMPLATE  ='SET_DEAL_PLAN_UPLOAD_DEAL_DOWNLOAD_TEMPLATE'
export const SET_DEAL_PLAN_DATA_TABLE_SELECTED_ROWS = 'SET_DEAL_PLAN_DATA_TABLE_SELECTED_ROWS';
export const SET_DEAL_PLAN_TABLE_DATA_LOADING = 'SET_DEAL_PLAN_TABLE_DATA_LOADING';

export const setDealPlanData = (data, isLoading= false) => {
    return {type:SET_DEAL_PLAN_DATA, payload:{data:cloneDeep(data), isLoading:isLoading}}
}

export const setDealPlanDataClone = (data, isLoading) => {
    return {type:SET_DEAL_PLAN_DATA_COPY, payload:{displayData:data, isLoading:isLoading}}
}

export const setDealPlanDefaultFilters = (filters) => ({
    type:SET_DEAL_PLAN_DATA_TABLE_DEFAULT_FILTERS,
    payload:cloneDeep(filters)
})

export const setDealPlanDataTableAppliedFilters = (filters) => ({
    type:SET_DEAL_PLAN_DATA_TABLE_SELECTED_FILTERS,
    payload:cloneDeep(filters)
})

export const setDealPlanCategoryList = (catList) => ({
    type:SET_DEAL_PLAN_CATEGORY_LIST,
    payload:cloneDeep(catList)
})

export const setDealDataTableCategoryFilterList = (catList) => ({
    type:SET_DEAL_PLAN_DATA_TABLE_CATEGORY_FILTER_LIST,
    payload:cloneDeep(catList)
})

export const setDealPlanDataTableVisibleColumnsList = (columnsList) => ({
    type:SET_DEAL_PLAN_DATA_TABLE_TOGGLE_COLUMNS,
    payload:cloneDeep(columnsList)
})

export const setDealPlanDataTableDealTypeFilterList= (dealTypeList)=>( {
    type:SET_DEAL_PLAN_DATA_TABLE_DEAL_TYPE_FILTER_LIST,
    payload: cloneDeep(dealTypeList)
    
})

export const setDealPlanDataTableDealStatusFilterList= (dealStatusList)=>( {
    type:SET_DEAL_PLAN_DATA_TABLE_DEAL_STATUS_FILTER_LIST,
    payload: cloneDeep(dealStatusList)
    
})

export const setDealDataTotalRecordCount = (recordCount) => ({
    type:SET_DEAL_PLAN_DATA_TOTAL_RECORD_COUNT,
    payload:cloneDeep(recordCount)
})

export const setDealPlanDataTablePageSize = (displayRecordCount = DEFAULT_TABLE_PAGE_SIZE) => ({
    type:SET_DEAL_PLAN_DATA_TABLE_PAGE_SIZE,
    payload:cloneDeep(displayRecordCount)
})
export const setDealPlanDataTableCurrentPage = (displayCurrentPage = 1) => ({
    type:SET_DEAL_PLAN_DATA_TABLE_CURRENT_PAGE,
    payload:cloneDeep(displayCurrentPage)
})
export const setDealDataTableEditable = (editEnabled) => ({
    type:SET_DEAL_PLAN_DATA_TABLE_EDIT,
    payload:cloneDeep({ editEnabled: editEnabled})
})
export const setDealDataTableAddRow = (isRowAdded = false) => ({
    type:SET_DEAL_PLAN_DATA_TABLE_ADD_ROW,
    payload:isRowAdded
})

export const setDealPlanDataTablePeakEventFlagFilterList= (peakEventFlagList)=>( {
    type:SET_DEAL_PLAN_DATA_TABLE_PEAK_EVENT_FLAG_FILTER_LIST,
    payload: cloneDeep(peakEventFlagList)
    
})

export const setDealPlanDataTableEventFilterList= (eventList)=>( {
    type:SET_DEAL_PLAN_DATA_TABLE_EVENT_FILTER_LIST,
    payload: cloneDeep(eventList)
    
})

export const setDealPlanUploadDealDownloadTemplate = (bool)=>({
    type : SET_DEAL_PLAN_UPLOAD_DEAL_DOWNLOAD_TEMPLATE,
    payload : bool
})

export const setDealPlanDataTableSelectedRows = (rows = []) =>({
    type : SET_DEAL_PLAN_DATA_TABLE_SELECTED_ROWS,
    payload : rows
})

export const setDealPlanTableDataLoading = (bool) => ({
    type:SET_DEAL_PLAN_TABLE_DATA_LOADING,
    payload:bool
})
import React,{ useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { infoModal } from './MessageModal';
import { useSelector } from 'react-redux';

export function NavigationLink(props) {
    const { text, redirectTo, isNav = true, hasAdminAccess = false, ...rest } = props;
    const { isDataModified, isModalOkButtonLoading, isDataLoading } = useSelector((state) => state.base)
    const [redirectingLink, setRedirectionLink] = useState(redirectTo);


    useEffect(() => {
        return () => {
            if (!isDataModified && !isModalOkButtonLoading && !isDataLoading) {
                return setRedirectionLink(redirectTo)
            } else {
                return setRedirectionLink('#')
            }
        }

    }, [isDataModified]);


    return (
        <Link
            replace={true}
            style={isNav ? {
                display: 'inline-block',
                margin: '0px 10px',
                borderWidth: '0px',
                borderColor: '#E2E3E4',
                cursor: 'pointer',
                boxShadow: '3px 2px 2px 2px grey',
                textDecoration: 'none',
                backgroundColor: '#E2E3E4',
                color: '#000',
                fontSize: '13px',
                padding: '4px 6px ',
                borderRadius: '0.35em',
                lineHeight: '18px',
                minWidth: '150px',
                textAlign: 'center'
            } : {
                display: 'inline-block',
                margin: '0px 10px',
                borderWidth: '0px',
                borderColor: 'transparent',
                cursor: 'pointer',
                textDecoration: 'none',
                backgroundColor: 'transparent',
                color: '#000',
                fontSize: '16px',
                padding: '4px 6px '
            }}
            onClick={() => isDataModified ? infoModal(
                'Unsaved data Present',
                'To navigate to the desired page, Please save or reset the data modified in the table'
            ) : null
            }

            to={redirectingLink}

            {...rest}
        >
            {text}
        </Link>
    );
}

export default NavigationLink;

import { cloneDeep } from "lodash";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../utilities/constants";

export const SET_PRICES_DATA_TABLE_DEFAULT_FILTERS = 'SET_PRICES_DATA_TABLE_DEFAULT_FILTERS';
export const SET_PRICES_DATA_TABLE_SELECTED_FILTERS = 'SET_PRICES_DATA_TABLE_SELECTED_FILTERS';
export const SET_PRICES_TABLE_DATA = 'SET_PRICES_TABLE_DATA';
export const SET_PRICES_TABLE_DATA_COPY = 'SET_PRICES_TABLE_DATA_COPY';
export const SET_PRICES_DATA_TOTAL_RECORD_COUNT = 'SET_PRICES_DATA_TOTAL_RECORD_COUNT';
export const SET_PRICES_DATA_TABLE_PAGE_SIZE = 'SET_PRICES_DATA_TABLE_PAGE_SIZE';
export const SET_PRICES_DATA_TABLE_CURRENT_PAGE = 'SET_PRICES_DATA_TABLE_CURRENT_PAGE';
export const SET_PRICES_DATA_TABLE_TOGGLE_COLUMNS = 'SET_PRICES_DATA_TABLE_TOGGLE_COLUMNS';
export const SET_PRICES_DATA_TABLE_COUNTRY_FILTER_LIST = 'SET_PRICES_DATA_TABLE_COUNTRY_FILTER_LIST';
export const SET_PRICES_DATA_TABLE_EDITABLE= 'SET_PRICES_DATA_TABLE_EDITABLE';
export const SET_PRICES_DATA_TABLE_RESET= 'SET_PRICES_DATA_TABLE_RESET';
export const SET_PRICES_DATA_TABLE_CANCEL_UPDATES= 'SET_PRICES_DATA_TABLE_CANCEL_UPDATES';
export const SET_PRICES_DATA_PRICES_UPLOADS= 'SET_PRICES_DATA_PRICES_UPLOADS';
export const SET_PRICES_TABLE_DATA_LOADING = 'SET_PRICES_TABLE_DATA_LOADING';
export const SET_PRICES_DATA_REMOVE_UPLOADS = 'SET_PRICES_DATA_REMOVE_UPLOADS';

export const setPricesData = (data, isLoadingBoolValue = false) => ({
    type:SET_PRICES_TABLE_DATA,
    payload:{ data:cloneDeep(data), isLoading:isLoadingBoolValue }
})

export const setPricesDataClone = (data, isLoadingBoolValue = false) => ({
    type:SET_PRICES_TABLE_DATA_COPY,
    payload:{ displayData:data, isLoading:isLoadingBoolValue }
})

export const setPricesDefaultFilters = (filters) => ({
    type:SET_PRICES_DATA_TABLE_DEFAULT_FILTERS,
    payload:cloneDeep(filters)
})
export const setPricesDataTableAppliedFilters = (filters) => ({
    type:SET_PRICES_DATA_TABLE_SELECTED_FILTERS,
    payload:cloneDeep(filters)
})

export const setPricesDataTableCountryFilterList = (catList) => ({
    type:SET_PRICES_DATA_TABLE_COUNTRY_FILTER_LIST,
    payload:cloneDeep(catList)
})

export const setPricesDataTableEditable = (editEnabled = false) => ({
    type:SET_PRICES_DATA_TABLE_EDITABLE,
    payload:cloneDeep(editEnabled)
})

export const setPricesDataTableResetData = (editEnabled) => ({
    type:SET_PRICES_DATA_TABLE_RESET,
    payload:cloneDeep(editEnabled)
})

export const setPricesDataCancelUpdates = (editEnabled) => ({
    type:SET_PRICES_DATA_TABLE_CANCEL_UPDATES,
    payload:cloneDeep(editEnabled)
})

export const setPricesDataTotalRecordCount = (recordCount) => ({
    type:SET_PRICES_DATA_TOTAL_RECORD_COUNT,
    payload:cloneDeep(recordCount)
})

export const setPricesDataTablePageSize = (displayRecordCount = DEFAULT_TABLE_PAGE_SIZE) => ({
    type:SET_PRICES_DATA_TABLE_PAGE_SIZE,
    payload:cloneDeep(displayRecordCount)
})

export const setPricesDataTableCurrentPage = (displayCurrentPage = 1) => ({
    type:SET_PRICES_DATA_TABLE_CURRENT_PAGE,
    payload:cloneDeep(displayCurrentPage)
})

export const setPricesDataTableVisibleColumnsList = (columnsList) => ({
    type:SET_PRICES_DATA_TABLE_TOGGLE_COLUMNS,
    payload:cloneDeep(columnsList)
})

export const setPricesDataUploads = (bool) => ({
    type:SET_PRICES_DATA_PRICES_UPLOADS,
    payload:bool
})

export const setPricesRemoveDataUploads = (bool) => ({
    type:SET_PRICES_DATA_REMOVE_UPLOADS,
    payload:bool
})
export const setPricesTableDataLoading = (bool) => ({
    type:SET_PRICES_TABLE_DATA_LOADING,
    payload:bool
})


export const SET_WEEKLY_PLAN_CURRENT_YEAR_SUMMARY = 'SET_WEEKLY_PLAN_CURRENT_YEAR_SUMMARY';
export const SET_WEEKLY_PLAN_LAST_YEAR_SUMMARY = 'SET_WEEKLY_PLAN_LAST_YEAR_SUMMARY';
export const SET_WEEKLY_PLAN_DIFF_CURRENT_LAST_SUMMARY = 'SET_WEEKLY_PLAN_DIFF_CURRENT_LAST_SUMMARY';
export const SET_WEEKLY_PLAN_SUMMARY_API_REFRESH = 'SET_WEEKLY_PLAN_SUMMARY_API_REFRESH';

const initialFSDataSets = {
    currentYear: { data: [], isLoading: false },
    lastYear: { data: [], isLoading: false },
    diffCurrentVsLast: { data: [], isLoading: false }
}

export const setWeeklyPlanFinancialSummaryApiTrigger = (hasFinancialSummaryData = false) => ({
    type:SET_WEEKLY_PLAN_SUMMARY_API_REFRESH,
    payload:hasFinancialSummaryData
})

export const setWeeklyPlanCurrentYearFinancialSummary = (data = initialFSDataSets) => ({
    type:SET_WEEKLY_PLAN_CURRENT_YEAR_SUMMARY,
    payload:data
})

export const setWeeklyPlanLastYearFinancialSummary = (data = initialFSDataSets) => ({
    type:SET_WEEKLY_PLAN_LAST_YEAR_SUMMARY,
    payload:data
})

export const setWeeklyPlanDiffCurrentVsaAstFinancialSummary = (data = initialFSDataSets) => ({
    type:SET_WEEKLY_PLAN_DIFF_CURRENT_LAST_SUMMARY,
    payload:data
})

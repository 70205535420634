
export const targetsTableColumnsList = [
    { key: "Asin", label: "Asin", hidden: false },
    { key: "CountryCode", label: "Country Code", hidden: true },
    { key: "DealStartDate", label: "Deal Start Date", hidden: false },
    { key: "DealEndDate", label: "Deal End Date", hidden: false },
    { key: "BusinessUnit", label: "Philips Category", hidden: false },
    { key: "CountryName", label: "Country", hidden: true },
    { key: "Mag", label: "Philips Mag", hidden: false },
    { key: "Ag", label: "Ag", hidden: false },
    { key: "AmazonGl", label: "Amazon GL", hidden: false },
    { key: "AmazonCategory", label: "Amazon Category", hidden: false },
    { key: "Comments", label: "Comments", hidden: true },
    { key: "ProductGroup", label: "Product Group/AmazonGl", hidden: true },
    { key: "TripledNetPriceLocalCurrency", label: "Triple Net Price", hidden: true },
    { key: "InvoicePriceLocalCurrency", label: "Net Invoice Price", hidden: true }
];
import moment from "moment";
import { cloneDeep, isEmpty, isNull, uniqBy, uniqueId } from "lodash";
import { errorModal, infoModal, warningModal } from "../components/MessageModal";
import {
    SET_FILTER_LIST,
    setCategoryMappingList,
    setCountryMapping,
    setDealTypeMappingList,
    setDealStatusMappingList,
    setMetricMappingList,
    setAmazonCategoryMappingList,
    setEventMappingList,
    setPeakEventFlagMappingList,
    setCurrentYearEventsList,
    setUploadableTargetEvents,
    setDataLoadingState,
    setIsEditableMetricMappingList,
    setMasterEventMappingList
} from "../store/actions/base-actions";
import { SET_ERROR } from "../store/actions/base-actions";
import CustomAxios from "./axios-service";
import { setDisplayCategories, setUserDetails } from "../store/actions/users";
import { extractKeyValue, getRangeOfDates, toObjectPayload } from "../utilities/common";
import {
    DEFAULT_BASE_DATA_PAGE_SIZE,
    DEFAULT_OFFSET, END_POINTS,
    applicationEditableFields,
    errorMessage
} from "../utilities/constants";
import {
    setDealPlanDataTableDealStatusFilterList,
    setDealPlanDataTableDealTypeFilterList,
    setDealPlanDataTablePeakEventFlagFilterList,
    setDealPlanDataTableEventFilterList
} from "../store/actions/deal-plan-actions";
import {
    setTargetsDataTableDatesColumns,
    setTargetsUploadableDatesColumns
} from "../store/actions/targets-action";
import { logOutUser } from '../helper/validateLoggedInCountryAccess';
import { setAppCountryMetricKpisList } from "../store/actions/admin-action";


export const getUserDetails = () => async (dispatch, getState) => {
    const errorList = getState().base.errorList;
    const emailFromSSO = localStorage.getItem('emailFromSSO')
    let responseData = false;

    await dispatch(setDataLoadingState(true));
    await CustomAxios(
        END_POINTS.USER_DETAILS.GET_LOGGEDIN_USER_DETAILS
            .replace(/emailAddress/, emailFromSSO),
        "GET", {}
    ).then(async (response) => {
        responseData = await response.data
        if ((typeof (responseData) === 'object' && Object.keys(responseData).includes('data')) && (
            Array.isArray(responseData.data) && isEmpty(responseData.data)
        )
        ) {
            return infoModal('Unauthorized User', 'User Does not have access to the tool')
        }
        await localStorage.setItem('userDetails', JSON.stringify(responseData))
        dispatch(setUserDetails(responseData))

    }).catch((err) => {

        const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
            ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
            err.response.data.message.errorMessage : errorMessage
        )
        console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
        errorList['UserDetails'] = toObjectPayload('GetStatus', displayResponse)
        responseData = false

        dispatch({
            type: SET_ERROR,
            payload: errorList
        });
        errorModal('UserDetails', displayResponse)
    }).finally(async () => await dispatch(setDataLoadingState(false)));
    return responseData
}


export const getAllCategories = (offset = DEFAULT_OFFSET) => async (dispatch, getState) => {
    const errorList = getState().base.errorList

    await dispatch(setDataLoadingState(true));
    await CustomAxios(END_POINTS.BASE.CATEGORY_LIST
        .replace(/pageSize/, DEFAULT_BASE_DATA_PAGE_SIZE).replace(/offsetCount/, offset),
        "GET", {}
    ).then(async (response) => {
        const responseData = await response.data
        await dispatch(setCategoryMappingList(responseData));

    }).catch((err) => {
        const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
            ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
            err.response.data.message.errorMessage : errorMessage
        )
        console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
        errorList['CategoryList'] = toObjectPayload('retrieve', displayResponse)
        dispatch({
            type: SET_ERROR,
            payload: errorList
        });
        errorModal("Categories List", displayResponse);
    }).finally(async () => await dispatch(setDataLoadingState(false)));
    return true;
}


export const getAllCountries = (offset = DEFAULT_OFFSET) => async (dispatch, getState) => {
    const errorList = getState().base.errorList;
    await dispatch(setDataLoadingState(true));
    await CustomAxios(END_POINTS.BASE.COUNTRY_LIST
        .replace(/pageSize/, DEFAULT_BASE_DATA_PAGE_SIZE).replace(/offsetCount/, offset),
        "GET", {}
    ).then(async (response) => {
        const responseData = await response.data
        await dispatch(setCountryMapping(responseData));

    }).catch((err) => {
        const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
            ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
            err.response.data.message.errorMessage : errorMessage
        )
        console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
        errorList['CountryList'] = toObjectPayload('RetrieveData', displayResponse)
        dispatch({
            type: SET_ERROR,
            payload: errorList
        });
        errorModal("Country List", displayResponse);
    }).finally(async () => await dispatch(setDataLoadingState(false)))
    return true;
}


export const getCategoryRefCountryList = (CountryId = 0) => async (dispatch, getState) => {
    const errorList = getState().base.errorList;

    if (CountryId === 0) {
        warningModal('Categories List', 'Invalid Country Provided or Country Not Supported')
        return logOutUser()
    }

    await CustomAxios(END_POINTS.BASE.COUNTRY_CATEGORY_LIST.replace(/countryid/, CountryId.toString()), "GET", {}
    ).then(async (response) => {
        await dispatch(setDisplayCategories(response.data))
    }).catch((err) => {
        const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
            ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
            err.response.data.message.errorMessage : errorMessage
        )
        errorList['CountryCategoryList'] = toObjectPayload('retrieve', displayResponse)
        dispatch({
            type: SET_ERROR,
            payload: errorList
        });
        errorModal("Categories w.r.t. Country List", displayResponse);
    }).finally(() => true);
    return true;
}


export const getMetricList = (countryId = 0, offset = DEFAULT_OFFSET, dataRetrivalType = 'User') => async (dispatch, getState) => {
    const errorList = getState().base.errorList;
    await dispatch(setDataLoadingState(true));

    if (countryId === 0) {
        warningModal('Metric Kpis', 'Invalid Country Provided or Country Not Supported')
        return logOutUser()
    }

    await CustomAxios(END_POINTS.BASE.METRIC_KPIS.replace(/countryid/, countryId)
        .replace(/pageSize/, DEFAULT_BASE_DATA_PAGE_SIZE).replace(/offsetCount/, offset),
        "GET", {}
    ).then(async (response) => {
        const responseData = await response.data
        if (dataRetrivalType !== 'Admin') {
            await dispatch(setMetricMappingList(responseData));
            if (
                typeof responseData === 'object' && Object.keys(responseData).includes('data') &&
                Array.isArray(responseData.data) && !isEmpty(responseData.data)) {
                const editableMetricList = cloneDeep(responseData.data).filter(kpi => kpi.isEditable).map(kpi => kpi.MetricName)
                await dispatch(setIsEditableMetricMappingList(editableMetricList))
            } else {
                await dispatch(setIsEditableMetricMappingList(applicationEditableFields))
            }
        } else {
            await dispatch(setAppCountryMetricKpisList(responseData.data, responseData.hasNext));
        }

    }).catch((err) => {
        const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
            ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
            err.response.data.message.errorMessage : errorMessage
        )
        console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
        errorList['MetricList'] = toObjectPayload('retrieve', displayResponse)
        dispatch({
            type: SET_ERROR,
            payload: errorList
        });
        errorModal("Metric Kpi", displayResponse);
    }).finally(async () => await dispatch(setDataLoadingState(false)))
    return true;
}


export const getAllDealTypes = (offset = DEFAULT_OFFSET) => async (dispatch, getState) => {
    const errorList = getState().base.errorList;
    await dispatch(setDataLoadingState(true));
    await CustomAxios(END_POINTS.BASE.DEAL_TYPE_LIST
        .replace(/pageSize/, DEFAULT_BASE_DATA_PAGE_SIZE).replace(/offsetCount/, offset),
        "GET", {}
    ).then(async (response) => {
        const responseData = await response.data
        await dispatch(setDealTypeMappingList(responseData));
        await dispatch(setDealPlanDataTableDealTypeFilterList(responseData));

    }).catch((err) => {
        const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
            ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
            err.response.data.message.errorMessage : errorMessage
        )
        console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
        errorList['DealTypeList'] = toObjectPayload('retrieve', displayResponse)
        dispatch({
            type: SET_ERROR,
            payload: errorList
        });
        errorModal("Deal Type List", displayResponse);
    }).finally(async () => await dispatch(setDataLoadingState(false)));
    return true;
}


export const getAllDealStatus = (offset = DEFAULT_OFFSET) => async (dispatch, getState) => {
    const errorList = getState().base.errorList;
    await dispatch(setDataLoadingState(true));
    await CustomAxios(END_POINTS.BASE.DEAL_STATUS_LIST
        .replace(/pageSize/, DEFAULT_BASE_DATA_PAGE_SIZE).replace(/offsetCount/, offset),
        "GET", {}
    ).then(async (response) => {
        const responseData = await response.data
        await dispatch(setDealStatusMappingList(responseData));
        await dispatch(setDealPlanDataTableDealStatusFilterList(responseData));

    }).catch((err) => {
        const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
            ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
            err.response.data.message.errorMessage : errorMessage
        )
        console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
        errorList['DealStatusList'] = toObjectPayload('retrieve', displayResponse)
        dispatch({
            type: SET_ERROR,
            payload: errorList
        });
        errorModal("Deal Status List", displayResponse);
    }).finally(async () => await dispatch(setDataLoadingState(false)));
    return true;
}


export const getAllAmazonCategories = (offset = DEFAULT_OFFSET) => async (dispatch, getState) => {
    const errorList = getState().base.errorList
    const amazonCategoryList = cloneDeep(getState().base.amazonCategoryList) || {}
    const hasNextKeyAsTrue = typeof amazonCategoryList === 'object' && Object.keys(amazonCategoryList).includes('hasNext')
    if (offset === DEFAULT_OFFSET && hasNextKeyAsTrue) {
        amazonCategoryList['hasNext'] = true
    }
    await dispatch(setDataLoadingState(true));
    await CustomAxios(END_POINTS.BASE.AMAZON_CATEGORY_LIST
        .replace(/pageSize/, DEFAULT_BASE_DATA_PAGE_SIZE).replace(/offsetCount/, offset),
        "GET", {}
    ).then(async (response) => {
        const responseData = await response.data
        if (offset !== DEFAULT_OFFSET && (responseData.hasNext !== false)) {
            Object.keys(amazonCategoryList).map(amz => {
                if (amz === 'data') {
                    amazonCategoryList[amz].push(...response.data.data)
                }
                if (amz === 'hasNext') {
                    amazonCategoryList[amz] = response.data.hasNext
                }
                return amz;
            })
            await dispatch(setAmazonCategoryMappingList({ ...amazonCategoryList, currentOffset: offset }));
        } else {
            await dispatch(setAmazonCategoryMappingList({ ...responseData, currentOffset: DEFAULT_OFFSET }));
        }

    }).catch((err) => {
        const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
            ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
            err.response.data.message.errorMessage : errorMessage
        )
        console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
        errorList['AmazonCategoryList'] = toObjectPayload('retrieve', displayResponse)
        dispatch({
            type: SET_ERROR,
            payload: errorList
        });
        errorModal("Amazon Category List", displayResponse);
    }).finally(async () => await dispatch(setDataLoadingState(false)));
    return true;
}


export const getAllPeakEventFlag = (offset = DEFAULT_OFFSET) => async (dispatch, getState) => {
    const errorList = getState().base.errorList;
    await dispatch(setDataLoadingState(true));
    await CustomAxios(END_POINTS.BASE.DEAL_PEAK_EVENTS_LIST
        .replace(/pageSize/, DEFAULT_BASE_DATA_PAGE_SIZE).replace(/offsetCount/, offset),
        "GET", {}
    ).then(async (response) => {
        const responseData = await response.data
        await dispatch(setDealPlanDataTablePeakEventFlagFilterList(responseData));
        await dispatch(setPeakEventFlagMappingList(responseData));

    }).catch((err) => {
        const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
            ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
            err.response.data.message.errorMessage : errorMessage
        )
        console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
        errorList['PeakEventFlagList'] = toObjectPayload('retrieve', displayResponse)
        dispatch({
            type: SET_ERROR,
            payload: errorList
        });
        errorModal("Peak Event Flag List", displayResponse);
    }).finally(async () => await dispatch(setDataLoadingState(false)))
    return true;
}


export const getAllEvent = (offset = DEFAULT_OFFSET) => async (dispatch, getState) => {
    const errorList = getState().base.errorList;
    await dispatch(setDataLoadingState(true));
    await CustomAxios(END_POINTS.BASE.DEAL_EVENTS_LIST
        .replace(/pageSize/, DEFAULT_BASE_DATA_PAGE_SIZE).replace(/offsetCount/, offset),
        "GET", {}
    ).then(async (response) => {
        const responseData = await response.data
        await dispatch(setDealPlanDataTableEventFilterList(responseData));
        await dispatch(setEventMappingList(responseData));

    }).catch((err) => {
        const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
            ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
            err.response.data.message.errorMessage : errorMessage
        )
        console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
        errorList['EventList'] = toObjectPayload('retrieve', displayResponse)
        dispatch({
            type: SET_ERROR,
            payload: errorList
        });
        errorModal(" Event List", displayResponse);
    }).finally(async () => await dispatch(setDataLoadingState(false)))
    return true;
}


export const setFilterData = (pageName, filterKey, values) => async (dispatch, getState) => {
    const pages = getState().base.pages;
    if (Array.isArray(pages) && !pages?.includes(pageName)) {
        errorModal('Invalid Page Name Provided', `Please provide correct page name, No filter value saved ${pageName}`);
    } else {
        let filters = getState().base?.filters;
        let pageFilter = (getState().base.filters[pageName] !== undefined) ? getState().base.filters[pageName] : pageName;
        if (typeof (pageFilter) === 'object' && !Object.keys(pageFilter).includes(filterKey)) {
            return infoModal('PageName - Filter Key Error', `Key: ${filterKey}  does not exist in ${pageName} filter`)
        }
        if (Array.isArray(values)) {
            pageFilter[filterKey] = values
        } else {
            if (Array.isArray(pageFilter[filterKey]) && pageFilter[filterKey].includes(values)) {
                let _list = pageFilter[filterKey].filter(
                    (k) => k !== values
                );
                pageFilter[filterKey] = _list;
            } else {
                if (Array.isArray(pageFilter[filterKey])) {
                    let _list = pageFilter[filterKey];
                    _list.push(values);
                    pageFilter[filterKey] = _list;
                } else {
                    pageFilter[filterKey] = values;
                }
            }
        }
        if (getState().base.filters[pageName] !== undefined) {
            await dispatch({ type: SET_FILTER_LIST, payload: filters })
        } else {
            await dispatch({ type: SET_FILTER_LIST, payload: toObjectPayload(pageName, filters) })
        }
    }
    return true;
}


export const getSkuDetails = (skuName = '') => async (dispatch, getState) => {
    const errorList = getState().base.errorList;
    await dispatch(setDataLoadingState(true));
    const userSelectedCountry = getState().accessControl.accessControlDetails.CountryId;
    let skuDetails = {}
    if (skuName !== '') {
        await CustomAxios(END_POINTS.BASE.SKU_DETAILS
            .replace(/countryid/, userSelectedCountry.toString())
            .replace(/skuName/, skuName)
            , "GET")
            .then(async (response) => {
                skuDetails = await response.data
                if (typeof (skuDetails) === 'object' && isEmpty(skuDetails)) {
                    return infoModal('Sku Not Found', `${skuName} is not available`)
                }
            }).catch((err) => {
                const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
                    ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
                    err.response.data.message.errorMessage : errorMessage
                )
                console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
                errorList['SkuDetails'] = toObjectPayload('SkuName', displayResponse)
                dispatch({
                    type: SET_ERROR,
                    payload: errorList
                });
                errorModal('Sku Details', displayResponse)
            }).finally(async () => await dispatch(setDataLoadingState(false)));
    }
    return skuDetails;

}


export const getSelectedCountryTargetEventsList = (year = moment().year().toString()) => async (dispatch, getState) => {
    const errorList = getState().base.errorList
    const userSelectedCountry = getState().accessControl.accessControlDetails.CountryId;
    await dispatch(setDataLoadingState(true));
    await CustomAxios(END_POINTS.TARGETS.GET_SELECTED_YEAR_EVENTS
        .replace(/countryid/, userSelectedCountry.toString())
        .replace(/selectedYear/, year), "GET", {})
        .then(async (response) => {
            const responseData = response.data
            responseData.map(eventName => (
                eventName.uid = uniqueId(),
                eventName.Id = eventName.EventId.toString() + '_' + eventName.EventType.toString()
            )
            )
            await dispatch(setCurrentYearEventsList(response.data))

        }).catch(async (err) => {
            const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
                ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
                err.response.data.message.errorMessage : errorMessage
            )
            console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
            errorList['TargetEventList'] = toObjectPayload('GetEventsList', displayResponse)
            await dispatch({
                type: SET_ERROR,
                payload: errorList
            });
            errorModal('Target Event List - No Events Available', displayResponse)
        }).finally(async () => await dispatch(setDataLoadingState(false)));
    return true;
}


export const getUploadableTargetEventsList = (year = moment().year().toString()) => async (dispatch, getState) => {
    const errorList = getState().base.errorList
    const userSelectedCountry = getState().accessControl.accessControlDetails.CountryId;
    await dispatch(setDataLoadingState(true));
    await CustomAxios(END_POINTS.TARGETS.GET_UPLOADABLE_EVENTS
        .replace(/countryid/, userSelectedCountry.toString())
        .replace(/selectedYear/, year), "GET", {})
        .then(async (response) => {
            const responseData = response.data
            if (Array.isArray(responseData) && !isEmpty(responseData)) {
                responseData.map(eventName => (
                    eventName.uid = uniqueId(),
                    eventName.Id = eventName.EventId.toString() + '_' + eventName.EventType.toString()
                )
                )
                const uniqEventDeatails = uniqBy(cloneDeep(responseData), (obj) => obj.Event)
                const targetDatesAsTableColumns = await getRangeOfDates(
                    moment(new Date(uniqEventDeatails[0].MainStartDate), 'DD-MM-YYYY').format('MMM-DD-YYYY'),
                    moment(new Date(uniqEventDeatails[0].MainEndDate), 'DD-MM-YYYY').format('MMM-DD-YYYY'),
                    'MMM-DD-YYYY', 'days'
                )
                // await dispatch(setTargetsUploadableDatesColumns(targetDatesAsTableColumns))
                if (moment(new Date(), 'DD-MM-YYYY')
                    .isBetween(
                        moment(new Date(uniqEventDeatails[0].MainStartDate), 'DD-MM-YYYY'),
                        moment(new Date(uniqEventDeatails[0].MainEndDate), 'DD-MM-YYYY').add(1, 'day')
                    ) ||
                    moment(new Date(), 'DD-MM-YYYY')
                        .isBefore(moment(new Date(uniqEventDeatails[0].MainStartDate), 'DD-MM-YYYY'))
                ) {
                    const editableDates = await getRangeOfDates(
                        moment(new Date(), 'DD-MM-YYYY').format('MMM-DD-YYYY'),
                        moment(new Date(uniqEventDeatails[0].MainEndDate), 'DD-MM-YYYY').format('MMM-DD-YYYY'),
                        'MMM-DD-YYYY', 'days'
                    )
                    await dispatch(setTargetsUploadableDatesColumns(editableDates))
                }
                await dispatch(setTargetsDataTableDatesColumns(targetDatesAsTableColumns))
                await dispatch(setUploadableTargetEvents(responseData))
            }

        }).catch(async (err) => {
            const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
                ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
                err.response.data.message.errorMessage : errorMessage
            )
            console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
            errorList['UploadableTargetEventList'] = toObjectPayload('GetEventsList', displayResponse)
            await dispatch({
                type: SET_ERROR,
                payload: errorList
            });
            errorModal('Target Event - No Events for Upload available', displayResponse)
        }).finally(async () => await dispatch(setDataLoadingState(false)));
    return true;
}


export const getAllMasterEvents = (offset = DEFAULT_OFFSET) => async (dispatch, getState) => {
    const errorList = getState().base.errorList;
    await dispatch(setDataLoadingState(true));
    await CustomAxios(END_POINTS.BASE.MASTER_DEAL_EVENTS_LIST
        .replace(/pageSize/, DEFAULT_BASE_DATA_PAGE_SIZE).replace(/offsetCount/, offset),
        "GET", {}
    ).then(async (response) => {
        const responseData = await response.data
        await dispatch(setMasterEventMappingList(responseData));

    }).catch((err) => {
        const displayResponse = (err && err.response && err.response?.data && err.response?.data?.message &&
            ((!isNull(err.response.data.message) || err.response?.data?.message !== '') && (typeof err.response?.data?.message === 'object')) ?
            err.response.data.message.errorMessage : errorMessage
        )
        console.log(extractKeyValue(err?.response?.data?.message, 'mainError'))
        errorList['MasterEventList'] = toObjectPayload('retrieve', displayResponse)
        dispatch({
            type: SET_ERROR,
            payload: errorList
        });
        errorModal(" Master Events List", displayResponse);
    }).finally(async () => await dispatch(setDataLoadingState(false)))
    return true;
}

import React, { useEffect } from 'react';
import { Layout, Typography } from 'antd';
import moment from 'moment';
import { HeaderNavigationPanel } from '../components/HeaderNavigationPanel';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isNull, isEmpty, cloneDeep, isUndefined } from 'lodash';
import {
    setUserAccessScreens,
    setAccessControlDetails,
    setUserLogInState,
    setSelectedCountryOnAccess
} from "./../store/actions/access-control-action";
import { getCategoryRefCountryList, getMetricList } from '../services/base';
import { setDisplayDetails, setUserCountryAccessControl, setUserDetails } from '../store/actions/users';
import { useIsAuthenticated } from '@azure/msal-react';
import { logOutUser } from '../helper/validateLoggedInCountryAccess';
import { setWeeklyPlanDataTableVisibleColumnsList } from '../store/actions/weekly-plan-actions';
import { weeklyPlanTableColumnsList } from '../utilities/weekly-plan';

const { Header, Content, Footer } = Layout;

const HomeLayout = () => {
    const { isAuthenticated } = useIsAuthenticated()
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const {
        accessControlDetails,
        accessScreen,
        selectedCountryOnAccess
    } = useSelector((state) => state.accessControl);
    const countryBasedCategoryList = useSelector((state) => state.users.userCategories.data);

    const {
        countryList, metricList
    } = useSelector((state) => state.base);

    const { data: countriesList } = countryList;

    const isUserLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn')) || null
    const accessScreenUserSelectedDetails = JSON.parse(localStorage.getItem('accessScreenUserSelectedDetails')) || null
    const accessScreenSelectedCountryObj = JSON.parse(localStorage.getItem('userSelectedCountryObject')) || null;
    const apiRetrievedUserDetails = JSON.parse(localStorage.getItem('userDetails')) || null;
    const displayDetails = JSON.parse(localStorage.getItem('displayDetails')) || null;
    const userSelectedCountryDetails = JSON.parse(localStorage.getItem('userSelectedCountryDetails')) || null;

    useEffect(() => {

        if (isNull(accessScreenUserSelectedDetails) || isNull(isUserLoggedIn)) {
            localStorage.setItem('accessScreenUserSelectedDetails', JSON.stringify({}));
            localStorage.setItem('isLoggedIn', false);
        } else {
            const isInvalidAccessControlDetail = !isNull(accessScreenUserSelectedDetails) && (
                typeof accessScreenUserSelectedDetails === 'object' && (
                    Object.keys(accessScreenUserSelectedDetails).includes('CountryId') && (
                        accessScreenUserSelectedDetails['CountryId'] === 0 ||
                        accessScreenUserSelectedDetails['CountryId'] === '' || isNull(accessScreenUserSelectedDetails['CountryId'])
                    )
                )
            ) || (!isUserLoggedIn)
            if (isInvalidAccessControlDetail) {
                localStorage.setItem('isLoggedIn', false);
                localStorage.setItem('accessScreenUserSelectedDetails', JSON.stringify({}));
                dispatch(setUserLogInState(false));
            }

        }
        if (isNull(accessScreenSelectedCountryObj)) {
            localStorage.setItem('userSelectedCountryObject', JSON.stringify({}))
        } else {
            dispatch(setSelectedCountryOnAccess(accessScreenSelectedCountryObj))
        }
        if (isNull(apiRetrievedUserDetails)) {
            localStorage.setItem('userDetails', JSON.stringify({}))
        } else {
            if (typeof (apiRetrievedUserDetails) === 'object' && !isEmpty(apiRetrievedUserDetails)) {
                dispatch(setUserDetails(apiRetrievedUserDetails))
            }
        }
        if (isNull(displayDetails)) {
            localStorage.setItem('displayDetails', JSON.stringify({}))
        } else {
            if (typeof (displayDetails) === 'object' && !isEmpty(displayDetails)) {
                dispatch(setDisplayDetails(displayDetails.UserName, accessScreenSelectedCountryObj.CountryCode))
            }
        }
        if (isNull(userSelectedCountryDetails)) {
            localStorage.setItem('userSelectedCountryDetails', JSON.stringify({ "data": [] }))
        } else {
            if (typeof (userSelectedCountryDetails) === 'object' && !isEmpty(userSelectedCountryDetails)) {
                dispatch(setUserCountryAccessControl(userSelectedCountryDetails))
            }
        }
    }, [])

    useEffect(() => {
        const retrieveSelectedCountryDetails = async () => {
            if ((!isNull(isUserLoggedIn) && isUserLoggedIn) && (
                !isNull(accessScreenUserSelectedDetails) && typeof accessScreenUserSelectedDetails === 'object' && !isEmpty(accessScreenUserSelectedDetails)
                && Object.keys(accessScreenUserSelectedDetails).includes('CountryId')
            )
            ) {
                const accessControlDetails = cloneDeep(accessScreenUserSelectedDetails);
                const isValidAccessControlDetail = !isNull(accessControlDetails['CountryId']) || (accessControlDetails['CountryId'] !== 0 || accessControlDetails['CountryId'] !== '')
                if (isValidAccessControlDetail) {
                    await dispatch(setUserLogInState(isUserLoggedIn));
                    if (isEmpty(countryBasedCategoryList)) {
                        await dispatch(getCategoryRefCountryList(accessControlDetails.CountryId));
                    }
                    if(typeof metricList === 'object' && Object.keys(metricList).includes('data') && Array.isArray(metricList.data) && isEmpty(metricList.data)){
                        await dispatch(getMetricList(accessControlDetails['CountryId']))
                    }

                    await dispatch(setAccessControlDetails(accessControlDetails));
                    await dispatch(setWeeklyPlanDataTableVisibleColumnsList(weeklyPlanTableColumnsList(accessControlDetails['Year'])))
                }
            }
        }
        retrieveSelectedCountryDetails()
    }, [isAuthenticated]);

    useEffect(() => {
        const accessScreenObj = cloneDeep(accessScreen);
        if ((!isNull(apiRetrievedUserDetails) && typeof (apiRetrievedUserDetails) === 'object') && (
            !isNull(accessScreenSelectedCountryObj) && (
                typeof (accessScreenSelectedCountryObj) === 'object' && !isEmpty(accessScreenSelectedCountryObj) &&
                Object.keys(accessScreenSelectedCountryObj).includes('CountryCode')
            )
        )
        ) {
            if ((apiRetrievedUserDetails && Object.keys(apiRetrievedUserDetails).includes('weeklyPlannerCountries') &&
                Array.isArray(apiRetrievedUserDetails['weeklyPlannerCountries']) && !isEmpty(apiRetrievedUserDetails['weeklyPlannerCountries'])
            ) && apiRetrievedUserDetails['weeklyPlannerCountries'].includes(accessScreenSelectedCountryObj.CountryCode)
            ) {
                accessScreenObj.push('Weekly')
            }
            if ((apiRetrievedUserDetails && Object.keys(apiRetrievedUserDetails).includes('monthlyPlannerCountries') &&
                Array.isArray(apiRetrievedUserDetails['monthlyPlannerCountries']) && !isEmpty(apiRetrievedUserDetails['monthlyPlannerCountries'])
            ) && apiRetrievedUserDetails['monthlyPlannerCountries'].includes(accessScreenSelectedCountryObj.CountryCode)
            ) {
                accessScreenObj.push('Monthly')
            }
            if ((apiRetrievedUserDetails && Object.keys(apiRetrievedUserDetails).includes('dealPlannerCountries') &&
                Array.isArray(apiRetrievedUserDetails['dealPlannerCountries']) && !isEmpty(apiRetrievedUserDetails['dealPlannerCountries'])
            ) && apiRetrievedUserDetails['dealPlannerCountries'].includes(accessScreenSelectedCountryObj.CountryCode)
            ) {
                accessScreenObj.push('Deal')
            }
            if ((apiRetrievedUserDetails && Object.keys(apiRetrievedUserDetails).includes('targetCountries') &&
                Array.isArray(apiRetrievedUserDetails['targetCountries']) && !isEmpty(apiRetrievedUserDetails['targetCountries'])
            ) && apiRetrievedUserDetails['targetCountries'].includes(accessScreenSelectedCountryObj.CountryCode)
            ) {
                accessScreenObj.push('Target')
            }
            if ((apiRetrievedUserDetails && Object.keys(apiRetrievedUserDetails).includes('pricesCountries') &&
                Array.isArray(apiRetrievedUserDetails['pricesCountries']) && !isEmpty(apiRetrievedUserDetails['pricesCountries'])
            ) && apiRetrievedUserDetails['pricesCountries'].includes(accessScreenSelectedCountryObj.CountryCode)
            ) {
                accessScreenObj.push('Prices')
            }
            if ((apiRetrievedUserDetails && Object.keys(apiRetrievedUserDetails).includes('hasAdminAccess') &&
                Array.isArray(apiRetrievedUserDetails['hasAdminAccess']) && !isEmpty(apiRetrievedUserDetails['hasAdminAccess'])
            ) && apiRetrievedUserDetails['admin'].includes(accessScreenSelectedCountryObj.CountryCode)
            ) {
                accessScreenObj.push('Admin')
            }
        }
        dispatch(setUserAccessScreens(accessScreenObj));

    }, []);

    useEffect(() => {
        if ((
            (isNull(selectedCountryOnAccess) || isUndefined(selectedCountryOnAccess)) ||
            (typeof selectedCountryOnAccess === 'object' && isEmpty(selectedCountryOnAccess))
        ) && (Array.isArray(countriesList) && !isEmpty(countriesList))
        ) {
            const countryObj = countriesList.find(Co => Co.CountryId === Number(accessControlDetails.CountryId));
            dispatch(setSelectedCountryOnAccess(countryObj))
        }
    }, [countriesList]);

    useEffect(() => {
        if (Array.isArray(accessScreen) && !isEmpty(accessScreen)) {
            if (accessScreen.includes('Admin')) {
                return navigateTo('admin-panel', { replace: false });
            }  else if (accessScreen.includes('Monthly')) {
                return navigateTo('monthly-planner', { replace: false });
            } else if (accessScreen.includes('Weekly')) {
                return navigateTo('weekly-planner', { replace: false });
            } else if (accessScreen.includes('Deal')) {
                return navigateTo('deal-planner', { replace: false });
            } else if (accessScreen.includes('Target')) {
                return navigateTo('target-planner', { replace: false });
            } else if (accessScreen.includes('Prices')) {
                return navigateTo('prices', { replace: false });
            } else {
                logOutUser()
                return navigateTo('login', { replace: false });
            }
        }
    }, [accessScreen])
    return (
        <Layout className="layout">
            <Header
                style={{
                    display: 'flex',
                    paddingLeft: 0,
                    paddingRight: 0,
                    alignItems: 'center',
                    zIndex: 10001
                }}
            >
                <HeaderNavigationPanel />
            </Header>
            <Content
                style={{
                    padding: '15px 30px',
                    minHeight: `calc(88dvh - 72px)`,
                    flex: 1
                }}
            >
                <Outlet />
            </Content>
            <Footer className='app-theme'
                style={{
                    textAlign: 'center',
                    minHeight: '62px',
                    zIndex:10001
                }}
            >
                <Typography.Title level={5} style={{color:'#fff'}}>Philips ©{moment().year()}</Typography.Title> 
            </Footer>
        </Layout>
    )
}

export default HomeLayout;

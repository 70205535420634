
export const logOutUser = async () => {
    localStorage.setItem('isLoggedIn', false)
    localStorage.setItem('userSelectedCountryObject', JSON.stringify({}))
    localStorage.setItem('accessScreenUserSelectedDetails', JSON.stringify({}))
    localStorage.setItem('userDetails', JSON.stringify({}))
    localStorage.setItem('displayDetails', JSON.stringify({}))
    localStorage.setItem('emailFromSSO', '')
    localStorage.setItem('userSelectedCountryDetails', JSON.stringify({ "data": [] }))
    localStorage.setItem('AccessToken', '')
};
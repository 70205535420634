import { cloneDeep } from "lodash";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../utilities/constants";

export const SET_TARGETS_DATA_TABLE_DEFAULT_FILTERS = 'SET_TARGETS_DATA_TABLE_DEFAULT_FILTERS';
export const SET_TARGETS_DATA_TABLE_SELECTED_FILTERS = 'SET_TARGETS_DATA_TABLE_SELECTED_FILTERS';
export const SET_TARGETS_USER_SELECTED_EVENT_DETAILS = 'SET_TARGETS_USER_SELECTED_EVENT_DETAILS';
export const SET_TARGETS_DATA = 'SET_TARGETS_TABLE_DATA';
export const SET_TARGETS_DATA_COPY = 'SET_TARGETS_DATA_COPY';
export const SET_TARGETS_DATA_TOTAL_RECORD_COUNT = 'SET_TARGETS_DATA_TOTAL_RECORD_COUNT';
export const SET_TARGETS_DATA_TABLE_PAGE_SIZE = 'SET_TARGETS_DATA_TABLE_PAGE_SIZE';
export const SET_TARGETS_DATA_TABLE_CURRENT_PAGE = 'SET_TARGETS_DATA_TABLE_CURRENT_PAGE';
export const SET_TARGETS_DATA_TABLE_TOGGLE_COLUMNS = 'SET_TARGETS_DATA_TABLE_TOGGLE_COLUMNS';
export const SET_TARGETS_DATA_TABLE_CATEGORY_FILTER_LIST = 'SET_TARGETS_DATA_TABLE_CATEGORY_FILTER_LIST';
export const SET_TARGETS_DATA_TABLE_EDIT = 'SET_TARGETS_DATA_TABLE_EDIT';
export const SET_TARGETS_DATA_TABLE_ROW_ADDED = 'SET_TARGETS_DATA_TABLE_ROW_ADDED';
export const SET_TARGETS_DATA_TABLE_RESET = 'SET_TARGETS_DATA_TABLE_RESET';
export const SET_TARGETS_DATA_TABLE_CANCEL_UPDATES = 'SET_TARGETS_DATA_TABLE_CANCEL_UPDATES';
export const SET_TARGETS_DATA_TARGET_UPLOADS = 'SET_TARGETS_DATA_TARGET_UPLOADS';
export const SET_TARGETS_DATA_TABLE_DATES_COLUMNS_LIST = 'SET_TARGETS_DATA_TABLE_DATES_COLUMNS_LIST'
export const SET_TARGETS_UPLOADABLE_DATES_COLUMNS_LIST = 'SET_TARGETS_UPLOADABLE_DATES_COLUMNS_LIST'
export const SET_TARGETS_DATA_TABLE_SELECTED_ROWS = 'SET_TARGETS_DATA_TABLE_SELECTED_ROWS';
export const SET_TARGETS_TABLE_DATA_LOADING = 'SET_TARGETS_TABLE_DATA_LOADING';


export const setTargetsDefaultFilters = (defaultfilters = {}) => ({
    type: SET_TARGETS_DATA_TABLE_DEFAULT_FILTERS,
    payload: defaultfilters
})

export const setTargetsDataTableAppliedFilters = (filtersInDataTable = {}) => ({
    type: SET_TARGETS_DATA_TABLE_SELECTED_FILTERS,
    payload: filtersInDataTable
})

export const setUserSelectedTargetEvent = (targetEvent = {}) => ({
    type:SET_TARGETS_USER_SELECTED_EVENT_DETAILS,
    payload:targetEvent
})

export const setTargetsDataTableData = (data = [], isDataLoading = false) => ({
    type: SET_TARGETS_DATA,
    payload: { data: cloneDeep(data), isLoading: isDataLoading }
})

export const setTargetsDataTableDataClone = (data = [], isDataLoading = false) => ({
    type: SET_TARGETS_DATA_COPY,
    payload: { displayData: cloneDeep(data), isLoading: isDataLoading }
})

export const setTargetsDataTableCurrentPage = (currentPage = 1) => ({
    type: SET_TARGETS_DATA_TABLE_CURRENT_PAGE,
    payload: currentPage
})

export const setTargetsDataTablePageSize = (pageSize = DEFAULT_TABLE_PAGE_SIZE) => ({
    type: SET_TARGETS_DATA_TABLE_PAGE_SIZE,
    payload: pageSize
})

export const setTargetsDataTotalRecordCount = (totalRecordCount = 0) => ({
    type: SET_TARGETS_DATA_TOTAL_RECORD_COUNT,
    payload: totalRecordCount
})

export const setTargetsDataTableVisibleColumnsList = (columnsList) => ({
    type: SET_TARGETS_DATA_TABLE_TOGGLE_COLUMNS,
    payload: cloneDeep(columnsList)
})

export const setTargetsDataTableEditable = (editEnabled = false) => ({
    type: SET_TARGETS_DATA_TABLE_EDIT,
    payload: editEnabled
})

export const setTargetsDataTableRowAdded = (isRowAdded = false) => ({
    type: SET_TARGETS_DATA_TABLE_ROW_ADDED,
    payload: isRowAdded
})
export const setTargetsDataTableResetData = (editEnabled = false) => ({
    type: SET_TARGETS_DATA_TABLE_RESET,
    payload: editEnabled
})

export const setTargetsDataCancelUpdates = (editEnabled = false) => ({
    type: SET_TARGETS_DATA_TABLE_CANCEL_UPDATES,
    payload: editEnabled
})

export const setTargetsUploadTemplateDownload = (templateDownloaded = false, downloadDetails = {}) => ({
    type: SET_TARGETS_DATA_TARGET_UPLOADS,
    payload: { isTemplateDownloaded: templateDownloaded, details: downloadDetails }
})

export const setTargetsDataTableDatesColumns = (targetDatesAsTableColumns = []) => ({
    type:SET_TARGETS_DATA_TABLE_DATES_COLUMNS_LIST,
    payload: targetDatesAsTableColumns
})

export const setTargetsUploadableDatesColumns = (uploadableDatesColumns = []) => ({
    type:SET_TARGETS_UPLOADABLE_DATES_COLUMNS_LIST,
    payload: uploadableDatesColumns
})

export const setTargetsDataTableCategoryFilterList = (catList = []) => ({
    type:SET_TARGETS_DATA_TABLE_CATEGORY_FILTER_LIST,
    payload:catList
})


export const setTargetsDataTableSelectedRows = (rows = []) =>({
    type : SET_TARGETS_DATA_TABLE_SELECTED_ROWS,
    payload : rows
})

export const setTargetsTableDataLoading = (bool) => ({
    type:SET_TARGETS_TABLE_DATA_LOADING,
    payload:bool
})
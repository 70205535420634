import { legacy_createStore as createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import monthlyPlanReducer from './reducers/monthly-plan';
import weeklyPlanReducer from './reducers/weekly-plan';
import baseReducer from './reducers/base';
import userReducer from './reducers/users';
import dealPlanReducer from './reducers/deal-plan';
import { targetsReducer } from './reducers/targets';
import { accessControlReducer } from './reducers/access-control';
import { dealSummaryReducer } from './reducers/deal-plan-summary';
import priceStateReducer from './reducers/prices';
import adminPanelReducer from './reducers/admin';

const rootReducer = combineReducers({
    base: baseReducer,
    accessControl: accessControlReducer,
    users: userReducer,
    monthlyPlan: monthlyPlanReducer,
    weeklyPlan: weeklyPlanReducer,
    dealPlan: dealPlanReducer,
    targets: targetsReducer,
    dealSummary: dealSummaryReducer,
    prices: priceStateReducer,
    adminPanel:adminPanelReducer
});


const nodeEnv = process.env.REACT_APP_ENV
const middlewares = nodeEnv === 'Dev' ? [thunk, logger] : [thunk]

const composeEnhancers =
    typeof window === "object" &&
        nodeEnv === "Dev" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
        : compose;
/* eslint-enable no-undef */

const enhancer = composeEnhancers(
    applyMiddleware(...middlewares)
    // other store enhancers if any
);
export default createStore(rootReducer, enhancer);

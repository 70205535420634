import React from 'react';
import { errorMessage } from "../../utilities/constants";
import { Alert } from "antd";


const AppErrorBoundary = () => {
    return (
        <Alert.ErrorBoundary message={'Uh-oh!'} description={errorMessage} banner={true} />
    )
}
export default AppErrorBoundary;
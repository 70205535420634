import React from 'react'
import ReactDOM from 'react-dom/client';
import './assets/fonts/achates-heavy.otf';
import './assets/fonts/achates-thin.ttf';
import './assets/fonts/achates-book.otf';
import './index.css';
import App from './App';
import { ErrorBoundaryGlobal } from './components/MyFallbackComponent/ErrorBoundaryGlobal';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './services/msal-service';
import { register } from './serviceWorker';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <ErrorBoundaryGlobal>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </ErrorBoundaryGlobal>
    </>
);
// Service Worker Run
register()